import React, { useState, useEffect } from "react";
import Layout from "../../layouts/default";
import { clearMessage } from "../../store/slices/message";
import { getSettlementRequests } from "../../store/slices/settlementRequests";
import { useSelector, useDispatch } from "react-redux";
import PaginationList from "../../components/Pagination";
import TableList from "../../components/TableList";

export const SettlementRequests = () => {
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  //const [itempage, setPage] = useState(1);
  const [filterData, setFilterData] = useState(null);

  const { message } = useSelector((state) => state.messageSlice);
  const { settlementRequests } = useSelector(
    (state) => state.settlementRequestsSlice
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSettlementRequests({ page, filterData }));
    setLoading(false);
  }, [dispatch, setLoading, page, filterData]);

  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);

  //Heading of the table
  const thead = [
    "#",
    "Supplier Name",
    "GST No.",
    "Case",
    "Status",
    "Last Modified",
  ];

  let dataList = [];
  let tdata = [];
  let paginationData = {};
  if (settlementRequests) {
    dataList = settlementRequests.response;
    paginationData = settlementRequests.pagination;
  }

  const dbFieldsForTd = [
    {
      field: "company_name",
    },
    {
      field: "gst_no",
    },
    {
      field: "case_count",
    },
    {
      field: "settlement_status",
    },
    { field: "date_modified" },
  ];

  return (
    <Layout heading="Settlement Requests">
      {message && <div className="alert alert-success">{message}</div>}
      <TableList
        thead={thead}
        tdata={tdata}
        fullData={dataList}
        dbFieldsForTd={dbFieldsForTd}
        loading={loading}
        paginationData={paginationData}
      />

      {paginationData && (
        <PaginationList
          paginationData={paginationData}
          setPage={setPage}
          setLoading={setLoading}
        />
      )}
    </Layout>
  );
};
