import { Card } from "react-bootstrap";

const SupplierSnippet = (props) => {
  const { defaulter } = props;
  return (
    <Card className="shadow p-2 border-0">
      <Card.Body>
        <Card.Title>
          <strong>Supplier</strong>
        </Card.Title>

        <h6>{defaulter?.supplier_trade_name}</h6>
        <div>{defaulter?.supplier_gstn}</div>
      </Card.Body>
    </Card>
  );
};
export default SupplierSnippet;
