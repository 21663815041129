//import "react-bootstrap-drawer/lib/style.css";
import React, { useState, useRef } from "react";
import * as S from "./style";
import { Col, Row } from "react-bootstrap";
import { Sidebar } from "../../components/Sidebar";
import { Topnav } from "../../components/Topnav";

const HomeLayout = (props) => {
  const stickyRef = useRef(null);
  const [openSidebar, setOpen] = useState(true);
  const { children } = props;
  const { heading } = props;
  const sidebarOnCollapse = {
    padding: 0,
    width: "20px",
  };

  const handleSidebarToggle = (e) => {
    setOpen(!openSidebar);
  };

  return (
    <S.HomeContainer fluid>
      <Row className="flex-xl-nowrap">
        <Col className="mx-0 px-0">
          <S.Topnav as={Topnav} stickyRef={stickyRef} />
        </Col>
      </Row>
      <Row ref={stickyRef} className="flex-xl-nowrap">
        {openSidebar ? (
          <S.LeftCol className="col-lg-2 col-md-2 col-xs-12 shadow-sm  px-0  ">
            <Sidebar openSidebar={openSidebar}>
              <S.CaretLeftSidebar onClick={handleSidebarToggle} />
            </Sidebar>
          </S.LeftCol>
        ) : (
          <S.LeftCol className="col-xs-1 col-md-1" style={sidebarOnCollapse}>
            <Sidebar openSidebar={openSidebar}>
              <S.CaretRightSidebar onClick={handleSidebarToggle} />
            </Sidebar>
          </S.LeftCol>
        )}
        <Col className={openSidebar === true && "col-lg-10 col-md-8 col-xs-12"}>
          <Row style={{ margin: 0 }}>
            <Col style={{ margin: 20 }}>
              <S.Heading>{heading}</S.Heading>
              {children}
            </Col>
          </Row>
        </Col>
      </Row>
    </S.HomeContainer>
  );
};

export default HomeLayout;
