import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import Layout from "../../layouts/default";
import {clearMessage} from "../../store/slices/message";
import {useSelector, useDispatch} from "react-redux";
import {useParams} from "react-router-dom";
import {getDefaulter} from "../../store/slices/defaulter";
//import { useNavigate } from "react-router-dom";
import SettlementPaymentFormEdit from "../../components/SettlementPayment/PaymentFormEdit";
import {
  updatePayment,
  createPaymentActions,
  getPayment,
} from "../../store/slices/payment";

const PaymentEdit = () => {
  const navigate = useNavigate();
  const {message} = useSelector((state) => state.messageSlice);
  const {defaulter} = useSelector((state) => state.defaulterSlice);
  const {paymentDetails, paymentDetailsLoaded} = useSelector(
    (state) => state.paymentSlice
  );

  const dispatch = useDispatch();
  //const navigate = useNavigate()
  const {defaulter_id, paid_to: paidTo, payment_id} = useParams();
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    dispatch(clearMessage());
    dispatch(createPaymentActions.resetnewPaymentAdded());
    const fetchDefaulter = async (id) => {
      if (id > 0) {
        let filterData = {id: Number(id), commission: true};
        dispatch(getDefaulter({filterData}));
      }
    };
    console.log("eeeeeeeee", defaulter_id);
    fetchDefaulter(defaulter_id);
  }, [defaulter_id, dispatch]);

  useEffect(() => {
    dispatch(createPaymentActions.resetnewPaymentAdded());
    const fetchPayment = async (id) => {
      if (id > 0) {
        dispatch(getPayment(id));
      }
    };
    fetchPayment(payment_id);
  }, [payment_id, dispatch]);

  //   useEffect(() => {
  //     if(newPaymentAdded){
  //       navigate(`/defaulter/${defaulter_id}`)
  //     }
  //   }, [newPaymentAdded,defaulter_id,navigate]);

  const pageTitle = `Paid to ${paidTo}`;

  const formSubmitHandler = (payload) => {
    try {
      console.log("payload##########", payload);
      setIsDisabled(true);
      dispatch(updatePayment(payload)).then(() => {
        setIsDisabled(false);
        navigate(`/defaulter/${defaulter_id}`);
      });
    } catch (error) {
      console.log(error);
    }
  };
  const paymentStatus = paymentDetails?.data?.status
    ? " ( " + paymentDetails.data.status.replaceAll("_", " ") + " ) "
    : "";

  return (
    <Layout heading={pageTitle + " " + paymentStatus}>
      {message && <div className="alert alert-success">{message}</div>}
      {paymentDetails?.data?.id > 0 && paymentDetailsLoaded && defaulter && (
        <SettlementPaymentFormEdit
          onformSubmit={formSubmitHandler}
          paidTo={paidTo}
          paymentDetails={paymentDetails?.data}
          defaulter={defaulter?.data?.defaulter}
          isDiabled={isDisabled}
        />
      )}
    </Layout>
  );
};

export default PaymentEdit;
