import * as API_URL from "../../constants/api";
import * as apiService from "../api";
import { getToken } from "../../helpers/common";

const getSettlementRequests = async (page, filterData) => {
  try {
    let data = {};

    let token = getToken();

    //if (gstn) {console
    data = { page, filterData };
    console.log("data", data);
    let result = await apiService.post(
      API_URL.GET_SETTLEMENT_REQUESTS,
      data,
      token
    );
    return result;
    // }
  } catch (e) {
    return { status: false, data: {}, message: e.message };
  }
};

const settlementRequestService = {
  getSettlementRequests,
};

export default settlementRequestService;
