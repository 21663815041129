import React, { useState, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import { getDefaulters } from "../../store/slices/creditqDefaulters/index";
import { clearMessage } from "../../store/slices/message";
import FilterForm from "../../pages/Defaulters/Filter";
import { mergeFields } from "../../helpers/DataTable";
import Layout from "../../layouts/default";
import PaginationList from "../../components/Pagination";
import TableList from "../../components/TableList";
import { currencyFormatter } from "../../helpers/common";
const CreditqDefaulters = () => {
  const defaultFilterData = {
    defaulter_gstn: "DoNotShowList",
  };
  const [filterData, setFilterData] = useState(defaultFilterData);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const { message } = useSelector((state) => state.messageSlice);
  const { creditqDefaulters } = useSelector(
    (state) => state.creditqDefaultersSlice
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (isFirstLoad) {
      setIsFirstLoad(false);
      return;
    }

    if (filterData) dispatch(getDefaulters({ page, filterData }));
    setLoading(false);
  }, [dispatch, setLoading, page, filterData]);

  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);

  const thead = [
    "S.No ",
    "Defaulter",
    "Supplier",
    "Due Amount",
    "Balance Amount",
    "Status",
    "Action",
  ];

  let defaultersData = [];
  let tdata = [];
  let paginationData = {};
  if (creditqDefaulters && creditqDefaulters.data) {
    defaultersData = creditqDefaulters.data.defaulters || [];
    paginationData = creditqDefaulters?.pagination;
  }

  const dbFieldsForTd = [
    {
      fields: ["buyer_trade_name", "buyer_gstn", "buyer_mobile"],
      callback: mergeFields,
      labels: ["Buyer Name", "GSTN", "Mobile"],
    },
    {
      fields: ["supplier_trade_name", "supplier_gstn", "supplier_mobile"],
      callback: mergeFields,
    },
    { field: "payment_due_amount", callback: currencyFormatterList },
    { field: "balance_amount", callback: currencyFormatterList },
    { field: "defaulters_status" },
  ];

  const tableActions = [
    {
      page: "CreditQ-Defaulter",
      icon: "",
      label: "View",
      iconOnly: true,
      link: ":id",
      linkVars: ["id"],
      onClick: false, //pass a function to handle the action
    },
  ];
  return (
    <Layout heading="CreditQ Defaulters List">
      <FilterForm
        setFilterData={setFilterData}
        setLoading={setLoading}
        defaultFilterData={defaultFilterData}
      />
      <br />
      {message && <div className="alert alert-success">{message}</div>}
      {!loading && (
        <TableList
          thead={thead}
          tdata={tdata}
          fullData={defaultersData}
          dbFieldsForTd={dbFieldsForTd}
          loading={loading}
          tableActions={tableActions}
          paginationData={paginationData}
        />
      )}

      {paginationData && (
        <PaginationList
          paginationData={paginationData}
          setPage={setPage}
          setLoading={setLoading}
        />
      )}
    </Layout>
  );
};
const currencyFormatterList = (field, defaulter) => {
  return (
    <>
      <div>{currencyFormatter(defaulter[field.field])}</div>
    </>
  );
};

export default CreditqDefaulters;
