import React, { useState, useEffect } from "react";
import Layout from "../../layouts/default";
import { Table, Spinner, Button } from "react-bootstrap";
import defaultersService from "../../services/payments";
import Modal from "react-modal";
import ModalStyles from "../../components/ModalStyles";
import ContactVerificationModal from "./ContactVerificationModal";
import { Field, Form, Formik } from "formik";
import { toast } from "react-toastify";
import { getDefaulter, getPermission } from "../../store/slices/defaulter";
import { useSelector, useDispatch } from "react-redux";
import { clearMessage } from "../../store/slices/message";

const DefaulterContacts = () => {
  const [defaulterContacts, setDefaulterContacts] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedEntry, setSelectedEntry] = useState([]);
  const [formSubmission, setFormSubmission] = useState("");
  const [queryParams, setQueryParams] = useState([]);
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [isNextPageDisabled, setIsNextPageDisabled] = useState(false);
  const [values, setValues] = useState({
    defaulterGst: "",
    supplierGst: "",
    mobile: "",
    dateFrom: "",
    dateTo: "",
  });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearMessage());
    dispatch(getPermission());
  }, []);
  const { permission } = useSelector((state) => state.defaulterSlice);
  const fetchDefaulterContacts = async (page = 1) => {
    let payload = {};
    payload.page = (page - 1) * 10;
    payload.queryParams = queryParams;

    setLoading(true);
    try {
      const result = await defaultersService.getDefaulterContactForVerification(
        payload
      );

      if (result.status === 200) {
        setDefaulterContacts(result.data);
        setTotalCount(result.count);
        toast.success(result.message);
        if (result.count < 10) setIsNextPageDisabled(true);
      } else {
        setDefaulterContacts([]);
        setIsNextPageDisabled(true);
        // setPage((prev) => (prev > 1 ? prev - 1 : prev));
        toast.error("No Data Found");
      }
    } catch (error) {
      // Handle any network or unexpected errors here
      console.error("Error fetching defaulter contacts:", error);
      toast.error("An error occurred while fetching data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!isNextPageDisabled) fetchDefaulterContacts(page);
  }, [queryParams, page]);

  const _tableHeader = () => {
    return (
      <thead>
        <tr>
          <th>S.No</th>
          <th>Supplier Name/GST</th>
          <th>Defaulter Name/GST</th>
          <th>Defaulter Mobile</th>
          <th>Defaulter Name</th>
          <th>Defaulter Designation</th>
          <th>Defaulter Gender</th>
          <th>Verification Status</th>
          <th>Date_Created</th>
        </tr>
      </thead>
    );
  };
  const handleActionButton = (payload) => {
    console.log("youuuu this is med: ", payload);
    setSelectedEntry(payload);
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };
  const formatDate = (dateString) => {
    const [datePart, timePart] = dateString.split("T");
    const [year, month, day] = datePart.split("-");
    const [hours, minutes] = timePart.split(":");

    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };

  const _itemsList = () => {
    if (!defaulterContacts.length) {
      return (
        <tr>
          <td colSpan="9">No record found!!</td>
        </tr>
      );
    }
    return defaulterContacts.map((item, index) => {
      return (
        <tr key={index}>
          <td>{(page - 1) * 10 + (index + 1)}</td>
          <td>{`${item.supplierTradeName}/${item.supplierGst}`}</td>
          <td>{`${item.defaulterTradeName}/${
            item.defaulterGst ? item.defaulterGst : item.defaulterPan
          }`}</td>
          <td>{item.contact_value}</td>
          <td>{item.defaulter_name}</td>
          <td>
            {item.defaulter_designation
              ? item.defaulter_designation.toUpperCase()
              : ""}
          </td>
          <td>{item.defaulter_gender}</td>
          <td>{item.verification_status}</td>
          <td>{item.entry_date ? formatDate(item.entry_date) : ""}</td>
          <td>
            {permission?.data[5].add_edit === 1 && (
              <button
                onClick={() => handleActionButton(item)}
                style={{
                  padding: "3px 5px",
                  border: "none",
                  borderRadius: "4px",
                  backgroundColor: "#007bff",
                  color: "#fff",
                  fontSize: "10px",
                  cursor: "pointer",
                  transition: "background-color 0.3s ease",
                  marginTop: "16px",
                }}
                onMouseEnter={(e) =>
                  (e.target.style.backgroundColor = "#0056b3")
                }
                onMouseLeave={(e) =>
                  (e.target.style.backgroundColor = "#007bff")
                }
              >
                ACTION
              </button>
            )}
          </td>
        </tr>
      );
    });
  };
  const handleSubmitStatus = async (params) => {
    console.log("This is handleSubmitStatus. ", params);
    setQueryParams(params);
    setPage(1);
  };

  return (
    <>
      <Layout heading="Defaulter Contacts">
        <div className="form-container">
          <div>
            <Formik
              onSubmit={(values, { setSubmitting }) => {
                console.log("values#####", values);
                handleSubmitStatus(values);
                setFormSubmission(false);
                setSubmitting(false);
              }}
              initialValues={values}
            >
              {({
                isSubmitting,
                errors,
                touched,
                setFieldValue,
                resetForm,
                setSubmitting,
              }) => (
                <Form>
                  <div style={{ display: "flex" }}>
                    <div className="input-filters">
                      <ul style={{ marginTop: "1em" }}>
                        <li>
                          <label>Defaulter GST/PAN</label>
                          <Field
                            className="input"
                            placeholder="Filter By"
                            name="defaulterGst"
                            type="text"
                          />
                        </li>
                      </ul>
                      <ul>
                        <li>
                          <label>Supplier GST</label>
                          <Field
                            className="input"
                            placeholder="Filter By"
                            name="supplierGst"
                            type="text"
                          />
                        </li>
                      </ul>
                      <ul>
                        <li>
                          <label>Defaulter Mobile</label>
                          <Field
                            className="input"
                            placeholder="Filter By"
                            name="mobile"
                            type="text"
                          />
                        </li>
                      </ul>
                      <ul>
                        <li>
                          <label>Date From</label>
                          <Field
                            name="dateFrom"
                            type="date"
                            className="form-control"
                            placeholder="Enter Call Hold From Date"
                            size="sm"
                            value={dateFrom}
                            onChange={(e) => {
                              setFieldValue("dateFrom", e.target.value);
                              setDateFrom(e.target.value);
                            }}
                            max={new Date().toISOString().split("T")[0]}
                          />
                        </li>
                      </ul>
                      <ul>
                        <li>
                          <label>Date To</label>
                          <Field
                            name="dateTo"
                            type="date"
                            className="form-control"
                            placeholder="Enter Call Hold From Date"
                            size="sm"
                            value={dateTo}
                            onChange={(e) => {
                              setFieldValue("dateTo", e.target.value);
                              setDateTo(e.target.value);
                            }}
                            max={new Date().toISOString().split("T")[0]}
                          />
                        </li>
                      </ul>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        marginLeft: "153px",
                      }}
                    >
                      <button
                        type="submit"
                        className="btn-theme"
                        disabled={isSubmitting}
                        onClick={() => setFormSubmission(true)}
                      >
                        {isSubmitting ? "Submitting..." : "SUBMIT"}
                      </button>
                      <button
                        className="btn-theme"
                        onClick={() => {
                          setQueryParams([]);
                          resetForm();
                          setDateFrom("");
                          setDateTo("");
                          setPage(1);
                          setIsNextPageDisabled(false);
                        }}
                        type="button"
                      >
                        RESET
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>

        <Table className="striped bordered hover">
          {_tableHeader()}
          <tbody>{_itemsList()}</tbody>
        </Table>
        {loading && <Spinner animation="border" />}
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            disabled={page === 1}
            onClick={() => {
              setPage((prev) => Math.max(prev - 1, 1));
              setIsNextPageDisabled(false);
            }}
          >
            Previous
          </Button>
          <span style={{ alignContent: "center", margin: "10px" }}>
            {" "}
            Page: {page}{" "}
          </span>
          <Button
            onClick={() => setPage((prev) => prev + 1)}
            disabled={isNextPageDisabled}
          >
            Next
          </Button>
        </div>
      </Layout>

      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={isOpen}
        onRequestClose={closeModal}
        style={ModalStyles}
        contentLabel="Example Modal"
      >
        <>
          <div>
            <button
              className="close"
              onClick={closeModal}
              style={{
                fontSize: "20px",
                fontWeight: "bold",
                color: "#000",
                backgroundColor: "transparent",
                border: "none",
                cursor: "pointer",
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                width: 0,
                height: 0,
                marginBottom: "12px",
              }}
            >
              &#10005;
            </button>
          </div>

          <ContactVerificationModal
            selectedEntry={selectedEntry}
            setIsOpen={setIsOpen}
            refreshPage={fetchDefaulterContacts}
          />
        </>
      </Modal>
    </>
  );
};

export default DefaulterContacts;
