import * as apiService from "../../services/api";
import * as API_URL from "../../constants/api";
import { getToken } from "../../helpers/common";
const getPayment = async (id) => {
  try {
    let token = getToken();
    let result = await apiService.get(API_URL.GET_CREDITQ_DEFAULTER_PAYMENT + id, token);
    return result;
    // }
  } catch (e) {
    return { status: false, data: {}, message: e.message };
  }
};

const paymentServices = {
    getPayment,
}

export default paymentServices;