import {Row,Col, Button} from "react-bootstrap"
import * as yup from 'yup';
import { useFormik } from "formik"

const SettlementPaymentPaidTo = (props) =>{
    const {onPaidToChange,shouldDisable} = props

    let paidToschema = yup.object().shape({
        paid_to: yup.string().required('Please select paid to option.'),
      });
    const formik = useFormik({
        enableReinitialize:true,
        initialValues:{
            paid_to:''
        },
        validationSchema:paidToschema,
        onSubmit:(values)=>{
            onPaidToChange(values.paid_to)
        }
    })

    console.log('shouldDisable#################okkkkkkkkkkkkk', shouldDisable)

    return (
        <form onSubmit={formik.handleSubmit}>
            <Row>
                <Col md={6}>
                    <input 
                    className="form-check-input" 
                    type="radio" 
                    name="paid_to" 
                    id="paidToCreditq" 
                    onChange={formik.handleChange}
                    value='creditq' 
                        />
                    <label className="form-check-label ps-2" htmlFor="paidToCreditq"> CreditQ </label>
                </Col>
                <Col md={6}>
                    <input 
                    className="form-check-input" 
                    type="radio" 
                    name="paid_to" 
                    id="paidToSupplier" 
                    onChange={formik.handleChange}
                    value='supplier' 
                        />
                    <label className="form-check-label ps-2" htmlFor="paidToSupplier"> Supplier </label>
                </Col>
            </Row>
            
            <Row>
                <Col sm={12} className="px-2 py-2">
                {formik.touched.paid_to &&
              formik.errors.paid_to ? (
                <span className="error text-danger">
                  {formik.errors.paid_to}
                </span>
              ) : null}
                </Col>
                <Col className="mt-3">
                    <Button variant='primary' type="submit" disabled={shouldDisable}>Process</Button>
                </Col>
            </Row>
        </form>
    
    )
}

export default SettlementPaymentPaidTo;