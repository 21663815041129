import { Container, Navbar, Image } from "react-bootstrap";
import styled from "styled-components";
import breakpoints from "../../styles/breakpoints";

export const ContainerBox = styled(Container)`
  ${(props) =>
    props.isSticky &&
    `
    position: fixed;
    top: 0;
    left: 0;
    z-index: 50 !important;
    animation: slide-down 0.7s;
  `}

  @keyframes slide-down {
    0% {
      opacity: 0;
      transform: translateY(-100%);
    }
    100% {
      opacity: 0.9;
      transform: translateY(0);
    }
  }
`;
export const TopNavbar = styled(Navbar)`
  background-color: #ffffff;
  color: #000000;
  z-index: 10;
  width: 100%;
  transform: translateZ(0);
  transition: all 0.5s;
  & .navbar-brand {
    color: #ffffff;
  }
  &.navbar {
    justify-content: flex-end;
  }
  & .navbar-nav {
    flex-direction: row;
    justify-content: flex-end;
    margin-left: auto;
    margin-right: 0 !important;
  }

  & .navbar-nav .nav-link {
    color: #000000;
    float: right important;
  }
`;

export const Logo = styled(Image)`
  border-radius: 5px;
  margin-left: 10px;
  @media only screen and ${breakpoints.xs} {
    display: none;
    margin: auto;
  }
`;
