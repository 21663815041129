import { Fragment } from "react";
import {Modal, Button} from "react-bootstrap"

const UIModalDialog = (props) =>{
    const {isShow, title, showBottom, onClose, size} = props
        const handleClose = () => {
        
        if(onClose){
            onClose(false)
        }
        
    }
    
    return (
        <Fragment>
            <Modal show={isShow} onHide={handleClose} size={size}>
                <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {props.children}
                </Modal.Body>
                <Modal.Footer>
               {showBottom && <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>}
                
                </Modal.Footer>
            </Modal>
        </Fragment>
    )
}
export default UIModalDialog;