import React from "react";

const DefaulterNumberBox = ({ number, onDelete }) => {
  // Only render the box if the number is not an empty string
  if (number.trim() === "") {
    return null;
  }

  return (
    <div style={styles.container}>
      <span>{number}</span>
      <button style={styles.deleteButton} onClick={() => onDelete(number)}>
        <strong style={styles.crossText}>X</strong>
      </button>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    border: "1px solid #ccc",
    padding: "8px",
    marginBottom: "8px",
    maxWidth: "200px", // Adjust the width as needed
  },
  deleteButton: {
    color: "#fff",

    border: "none",
    padding: "8px",
    cursor: "pointer",
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  crossText: {
    fontWeight: "bold",
  },
};

export default DefaulterNumberBox;
