import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";
import DefaultersService from "..//../services/defaulters";

export const getSupplier = createAsyncThunk(
  "defaulters/getSupplier",
  async ({ filterData }, thunkAPI) => {
    try {
      const result = await DefaultersService.getSupplier(filterData);
      console.log("########result", result);
      if (result.status) {
        return result;
      } else {
        thunkAPI.dispatch(setMessage(result.data.message));
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getCQSupplier = createAsyncThunk(
  "defaulters/getCQSupplier",
  async ({ filterData }, thunkAPI) => {
    try {
      const result = await DefaultersService.getCQSupplier(filterData);
      if (result.status === 200) {
        return result;
      } else {
        thunkAPI.dispatch(setMessage(result.data.message));
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = { supplier: {}, cqSupplier: {} };

const supplierSlice = createSlice({
  name: "suplier",
  initialState,

  extraReducers: {
    [getSupplier.fulfilled]: (state, action) => {
      state.supplier = action.payload;
    },
    [getSupplier.rejected]: (state, action) => {
      state.supplier = null;
    },
    [getCQSupplier.fulfilled]: (state, action) => {
      state.cqSupplier = action.payload;
    },
    [getCQSupplier.rejected]: (state, action) => {
      state.cqSupplier = null;
    },
  },
});

export default supplierSlice.reducer;
