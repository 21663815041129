import * as API_URL from "../../constants/api";
import * as apiService from "../api";
import { getToken } from "../../helpers/common";

const getDefaulters = async (page, filterData) => {
  try {
    let data = {};
    let token = getToken();
    data = { page, filterData };
    console.log("data", data);
    let result = await apiService.post(API_URL.GET_CREDITQ_DEFAULTERS, data, token);
    return result;
    // }
  } catch (e) {
    return { status: false, data: {}, message: e.message };
  }
};

const getDefaulter = async ( _,data) => {
  try {
    let token = getToken();
    let result = await apiService.post(API_URL.GET_CREDITQ_DEFAULTER, data, token);
    return result;
    // }
  } catch (e) {
    return { status: false, data: {}, message: e.message };
  }
};

const downloadPaymentProof = async (data, token) => {
  let result = apiService.downloadFile(
    API_URL.PAYMENT_PROOF_DOWNLOAD_CREDITQ_DEFAULTER,
    data,
    token
  );
  return result;
};
const CreditqDefaulters = {
    getDefaulters,
    downloadPaymentProof,
    getDefaulter
};


export default CreditqDefaulters;
