import { useState } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import * as yup from "yup";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import { dateDifference } from "../../services/Misc";
import { toast } from "react-toastify";
import BuyerSnippet from "../Defaulter/BuyerSnippet";
import SupplierSnippet from "../Defaulter/SupplierSnippet";
import ServicesChargesInvoice from "./settlementInvoice";
import { format } from "date-fns";

const SettlementPaymentForm = (props) => {
  const { defaulter, onformSubmit, paidTo, isDisabled } = props;
  const [settlementAmount, setSettlementAmount] = useState(0);
  const [writeoffAmount, setWriteoffAmount] = useState(0);
  const [serviceChargePercentage, setServiceChargePercentage] = useState(0);
  const [
    serviceChargeAmountAfterDiscount,
    setServiceChargeAmountAfterDiscount,
  ] = useState(0);
  const [serviceChargeAmount, setServiceChargeAmount] = useState(0);
  const [totalPayableAmount, setTotalPayableAmount] = useState(0);
  const [serviceChargeGst, setServiceChargeGst] = useState(0);
  const [disputeMatter, setDisputeMatter] = useState(0);
  const [paymentProofFileBase64, setPaymentProofFileBase64] = useState("");
  const [paymentProofFileName, setPaymentProofFileName] = useState("");
  const [isCommissionSlabExist, setIsCommissionSlabExist] = useState(true);
  const [serviceChargeDiscount, setServiceChargeDiscount] = useState(0);
  console.log("defaulter data##########", defaulter);
  const backButtonLink = `/defaulter/${defaulter?.id}`;
  const userAuth = localStorage.getItem("user");
  let userId = JSON.parse(userAuth).data.id;

  const _selectFiles = async (e) => {
    var fileToLoad = e.target.files[0];
    //setUploadedFileName({ fileToLoad, docType })
    var fileReader = new FileReader();
    var base64 = [];
    fileReader.onload = function (fileLoadedEvent) {
      base64.push(fileLoadedEvent.target.result);
      setPaymentProofFileBase64(base64);
      setPaymentProofFileName(fileToLoad.name);
    };
    if (fileToLoad?.size <= 2000000) {
      fileReader.readAsDataURL(fileToLoad);
    } else {
      toast.error("File is too large.");
    }
  };

  const getDueAmount = () => {
    let reportedAmount = defaulter?.payment_due_amount
      ? +defaulter.payment_due_amount
      : 0;
    let paidAmount = defaulter?.paid_amount ? +defaulter.paid_amount : 0;
    return reportedAmount - paidAmount;
  };

  const getDueDays = () => {
    let days = 0;
    if (
      format(new Date(defaulter.consent_signed_date), "yyyy-MM-dd") >
      format(new Date(defaulter.verified_date), "yyyy-MM-dd")
    ) {
      days = dateDifference(
        defaulter.payment_due_date,
        defaulter.consent_signed_date
      );
    } else {
      days = dateDifference(
        defaulter.payment_due_date,
        defaulter.verified_date
      );
    }

    return days;
  };

  const getMaxCommissionDaysLimit = () => {
    let maxDaysLimit = 0;
    defaulter?.commissionslab.map((slab) => {
      if (slab.max_days > maxDaysLimit) {
        maxDaysLimit = slab.max_days;
      }
      return slab;
    });
    return maxDaysLimit;
  };
  const getCommission = (serviceChargeOnAmount) => {
    let commission = 0;

    if (defaulter?.serviceCharge && +defaulter.serviceCharge > 0) {
      return new Promise((resolve, _) => {
        setIsCommissionSlabExist(true);
        resolve(defaulter.serviceCharge);
      });
    }

    if (defaulter?.commissionslab.length) {
      let maxDays = getMaxCommissionDaysLimit();
      // let settlementValue = formik.values.settlement_amount
      let dueDays = +formik.values.payment_due_days;
      dueDays = dueDays > maxDays ? maxDays : dueDays;
      console.log("dueDays getCommission", dueDays);
      //console.log('disputeDiscountHandler########### settlement commission data ##########', settlementValue)
      //console.log('disputeDiscountHandler########### settlement amount and days', settlementValue,dueDays, defaulter?.commissionslab)
      console.log("defaulter?.commissionslab", defaulter?.commissionslab);
      let matchedcommissionSlab = defaulter?.commissionslab.filter((slab) => {
        // console.log(
        //   "Number(slab.min_amount), Number(serviceChargeOnAmount)",
        //   Number(slab.min_amount),
        //   Number(serviceChargeOnAmount)
        // );
        // console.log(
        //   " Number(slab.max_amount) >= Number(serviceChargeOnAmount)",
        //   Number(slab.max_amount),
        //   Number(serviceChargeOnAmount)
        // );
        // console.log(
        //   "Number(slab.min_days) <= Number(dueDays)",
        //   Number(slab.min_days),
        //   Number(dueDays)
        // );
        // console.log(
        //   "Number(slab.max_days) >= Number(dueDays)",
        //   Number(slab.max_days),
        //   Number(dueDays)
        // );
        if (
          Number(slab.min_amount) <= Number(serviceChargeOnAmount) &&
          Number(slab.max_amount) >= Number(serviceChargeOnAmount) &&
          Number(slab.min_days) <= Number(dueDays) &&
          Number(slab.max_days) >= Number(dueDays)
        ) {
          // console.log(`comassion row matched ###########okkkkkkkkkkkkkkkkkk dueDays:${dueDays}, settlementValue:${serviceChargeOnAmount}`, slab)
          return true;
        } else {
          return false;
        }
      });
      console.log(
        "disputeDiscountHandler########### settlement commission data ##########filter",
        matchedcommissionSlab,
        `Days:${dueDays}`,
        `Max Days limit:${maxDays}`
      );
      if (matchedcommissionSlab.length) {
        commission = matchedcommissionSlab[0].commission_value;
        setIsCommissionSlabExist(true);
      } else {
        setIsCommissionSlabExist(false);
      }
    }
    return new Promise((resolve, reject) => {
      resolve(commission);
    });
  };

  const getCommissionAmount = (amount, commissionPercent, discount) => {
    let serviceCharge = 0;
    return new Promise((resolve, reject) => {
      serviceCharge = (+amount * Number(commissionPercent)) / 100;
      if (discount > 0) {
        serviceCharge = Number(serviceCharge) * (1 - Number(discount) / 100);
      } else {
        serviceCharge = (+amount * Number(commissionPercent)) / 100;
      }
      // console.log(`disputeDiscountHandler########### getCommissionAmount ooooook ${serviceCharge}, amount:${amount}, commissionPercent:${commissionPercent}, discount:${discount}`)
      resolve(serviceCharge.toFixed(0));
    });
  };

  const settlementAmountChangeHandler = async (event) => {
    let writeoff = 0;
    let dueAmount = 0;

    formik.setFieldValue("settlement_amount", event.target.value);
    //console.log('settlement status ', formik?.values?.settlement_status)
    let settlementAmount = event.target.value;
    let serviceChargeOnAmount = event.target.value;
    let findCommissionSlabOnAmount = serviceChargeOnAmount;
    dueAmount = getDueAmount();
    if (formik?.values?.settlement_status === "full_settled") {
      // console.log(`wrieoff amount dueamount:${dueAmount}, settlement amount: ${event.target.value}`, )
      writeoff = +dueAmount - +event.target.value;
    }
    if (
      formik?.values?.settlement_status === "full_settled" &&
      paidTo === "supplier"
    ) {
      serviceChargeOnAmount = dueAmount;
      findCommissionSlabOnAmount = dueAmount;
    }

    if (
      formik?.values?.settlement_status === "partial_settled" &&
      paidTo === "supplier"
    ) {
      findCommissionSlabOnAmount = dueAmount;
    }

    if (
      formik?.values?.settlement_status === "full_settled" &&
      paidTo === "creditq"
    ) {
      let calculateServiceChargeOnAmount = getServiceChargeOnAmount(
        settlementAmount,
        dueAmount
      );
      serviceChargeOnAmount = calculateServiceChargeOnAmount;
      findCommissionSlabOnAmount = calculateServiceChargeOnAmount;
    }

    console.log("serviceChargeOnAmount#####", serviceChargeOnAmount);
    setSettlementAmount(serviceChargeOnAmount);
    let discount = 0;
    if (paidTo === "supplier" && formik.values.discount_offered > 0) {
      discount = formik.values.discount_offered;
    }
    setWriteoffAmount(writeoff);
    let serviceChargePercent = await getCommission(findCommissionSlabOnAmount);
    setServiceChargePercentage(serviceChargePercent);
    getPayAbleAmount(serviceChargeOnAmount, serviceChargePercent, discount);
  };

  const getServiceChargeOnAmount = (settlementAmount, balanceAmount) => {
    let amount = Math.round((balanceAmount / 100) * 70);
    if (amount > settlementAmount) {
      return amount;
    }
    if (settlementAmount >= amount) {
      return settlementAmount;
    }
  };

  const getPayAbleAmount = async (
    settlementValue,
    serviceChargePercent,
    discountOffer
  ) => {
    console.log(
      "getPayAbleAmount######",
      settlementValue,
      serviceChargePercent,
      discountOffer
    );
    let serviceChargeBeforeDiscount = 0;
    serviceChargeBeforeDiscount = await getCommissionAmount(
      settlementValue,
      serviceChargePercent
    );
    setServiceChargeAmount(serviceChargeBeforeDiscount);

    let serviceCharge = 0;
    serviceCharge = await getCommissionAmount(
      settlementValue,
      serviceChargePercent,
      +discountOffer
    );

    setServiceChargeAmountAfterDiscount(serviceCharge);
    let gstAmount = (+serviceCharge * 18) / 100;
    setServiceChargeGst(parseInt(gstAmount));
    let payableServiceCharge = Number(serviceCharge) + Number(gstAmount);
    setTotalPayableAmount(payableServiceCharge.toFixed(0));

    // console.log(`disputeDiscountHandler########### getPayAbleAmount
    //   Amount:${settlementValue},
    //   ServiceCharge Percent:${serviceChargePercent},
    //   discountOffered:${discountOffer}, service Charge:${serviceCharge}`
    // );
  };

  const disputeDiscountHandler = async (discount) => {
    try {
      formik.setFieldValue("discount_offered", discount);
      let serviceChargeOnAmount = settlementAmount;
      setServiceChargeDiscount(discount);
      if (
        formik?.values?.settlement_status === "full_settled" &&
        paidTo === "supplier"
      ) {
        let dueAmount = getDueAmount();
        serviceChargeOnAmount = dueAmount;
      }
      if (
        formik?.values?.settlement_status === "full_settled" &&
        paidTo === "creditq"
      ) {
        let dueAmount = getDueAmount();
        let calculateServiceChargeOnAmount = getServiceChargeOnAmount(
          settlementAmount,
          dueAmount
        );
        serviceChargeOnAmount = calculateServiceChargeOnAmount;
      }

      let serviceChargePercent = await getCommission(serviceChargeOnAmount);
      getPayAbleAmount(serviceChargeOnAmount, serviceChargePercent, discount);
    } catch (error) {
      console.log(error);
    }
  };

  const disputeStatusHandler = async (event) => {
    try {
      //if(!event.target.value){
      formik.setFieldValue("discount_offered", 0);
      formik.setFieldValue("dispute_matter", event.currentTarget.value);
      //}
      setDisputeMatter(event.target.value);

      if (event.target.value == 0) {
        setServiceChargeDiscount(0);
      }

      let serviceChargeOnAmount = settlementAmount;
      if (
        formik?.values?.settlement_status === "full_settled" &&
        paidTo === "supplier"
      ) {
        let dueAmount = getDueAmount();
        serviceChargeOnAmount = dueAmount;
      }

      if (
        formik?.values?.settlement_status === "full_settled" &&
        paidTo === "creditq"
      ) {
        let dueAmount = getDueAmount();
        let calculateServiceChargeOnAmount = getServiceChargeOnAmount(
          settlementAmount,
          dueAmount
        );
        serviceChargeOnAmount = calculateServiceChargeOnAmount;
      }

      let serviceChargePercent = await getCommission(serviceChargeOnAmount);
      getPayAbleAmount(serviceChargeOnAmount, serviceChargePercent, 0);
    } catch (error) {
      console.log(error);
    }
  };

  const settlementStatusChangeHandler = (event) => {
    try {
      // let writeoff = 0
      formik.resetForm();
      formik.setFieldValue("writeoff_amount", 0);
      formik.setFieldValue("settlement_amount", 0);
      formik.setFieldValue("service_charge_amount", 0);
      formik.setFieldValue("service_charge_amount_after_discount", 0);
      formik.setFieldValue("dispute_matter", "");
      formik.setFieldValue("service_charge_percentage", 0);
      formik.setFieldValue("discount_offered", 0);

      console.log("settlementStatusChangeHandler", event.target.value);
      formik.setFieldValue("settlement_status", event.target.value);
      setWriteoffAmount(0);
      setServiceChargePercentage(0);
      setServiceChargeAmount(0);
      setServiceChargeAmountAfterDiscount(0);
      setTotalPayableAmount(0);
      setServiceChargeGst(0);
    } catch (error) {
      console.log(error);
    }
  };
  const isDiscountAllowed = () => {
    return +disputeMatter === 1;
  };

  const getsettlementPaymentStatus = (data) => {
    const requiredFields = [
      "balance_amount",
      "payment_due_amount",
      "payment_due_days",
      "settlement_amount",
      "service_charge_percentage",
      "service_charge_amount_after_discount",
      "service_charge_gst",
      "total_payable_amount",
      "settlement_status",
      "invoice_type",
      "service_charge_received",
    ];

    return new Promise((resolve, reject) => {
      let status = "pending_for_approval";
      for (let fieldName of requiredFields) {
        // console.log('required filednames#############', fieldName, data[fieldName])
        let fieldValue = data[fieldName];
        if (!fieldValue) {
          status = "draft";
        }
        if (
          fieldName === "service_charge_received" &&
          data.service_charge_received === "Paid"
        ) {
          if (!data.payment_proof || data.payment_proof?.name === "") {
            status = "draft";
          }
        }
      }
      resolve(status);
    });
  };

  const SUPPORTED_FORMATS = ["jpg", "jpeg", "png", "pdf"];
  let paidToschema = yup.object().shape({
    payment_due_amount: yup
      .number()
      .positive()
      .min(1)
      .required("Enter an valid due amount."),
    paid_amount: yup
      .number()
      .positive()
      .min(0)
      .required("Enter an valid paid amount."),
    payment_due_days: yup
      .number()
      .positive()
      .min(1)
      .required("Payment due days is required field."),
    settlement_amount: yup
      .number()
      .positive()
      .min(1)
      .max(getDueAmount())
      .required("Settlement amount is required field."),
    settlement_status: yup
      .string()
      .required("Settlement status is required field."),
    remarks: yup.string().required("Remarks is required field."),
    payment_proof: yup
      .mixed()
      .nullable()
      .when("service_charge_received", (chargePayStatus) => {
        if (chargePayStatus === "Paid") {
          return yup
            .string()
            .required("Payment proof is a required field")
            .test(
              "format",
              "Only the following formats are accepted: .jpeg, .jpg, .png and .pdf",
              (value) => {
                return (
                  paymentProofFileName &&
                  SUPPORTED_FORMATS.includes(
                    paymentProofFileName.split(".").pop()
                  )
                );
              }
            );
        }
      }),
    discount_offered: yup.number().positive().min(0).max(100),
  });

  const formik = useFormik({
    enableReinitialize: false,
    initialValues: {
      supplier_id: defaulter.supplier_id,
      payment_due_amount: defaulter?.payment_due_amount
        ? defaulter?.payment_due_amount
        : 0,
      balance_amount: getDueAmount(),
      paid_amount: defaulter?.paid_amount ? defaulter.paid_amount : 0,
      settlement_status: "",
      payment_due_days: getDueDays(),
      settlement_amount: settlementAmount,
      writeoff_amount: writeoffAmount,
      service_charge_percentage: "",
      discount_offered: 0,
      service_charge_gst: "",
      total_payable_amount: 0,
      service_charge_received: paidTo === "creditq" ? "Paid" : "",
      invoice_type: "",
      remarks: "",
      noc_signed_status: defaulter?.noc_signed_status === 1 ? 1 : 0,
      payment_proof: "",
      payment_proof_name: "",
      payment_proof_file: "",
      defaulter_case_id: defaulter?.id,
      paid_to: paidTo,
      service_charge_amount: "",
      service_charge_amount_after_discount: "",
      status: "draft",
      remark_by: userId,
      remark_for: "other",
      payment_proof_details: {},
    },
    validationSchema: paidToschema,
    onSubmit: async (values) => {
      values.service_charge_gst = serviceChargeGst;
      values.service_charge_percentage = serviceChargePercentage;
      values.total_payable_amount = totalPayableAmount;
      values.writeoff_amount = writeoffAmount;
      values.service_charge_amount_after_discount =
        serviceChargeAmountAfterDiscount;
      values.service_charge_amount = serviceChargeAmount;

      console.log("valuessssss form data #############", values);
      if (!isCommissionSlabExist) {
        return;
      }
      if (formik.values.service_charge_received === "Paid") {
        values.invoice_type = "Tax Invoice";
        values.payment_proof_name = paymentProofFileName;
        values.payment_proof_file = paymentProofFileBase64;
        values.payment_proof_details.name = values.payment_proof.name;
        values.payment_proof_details.type = values.payment_proof.type;
        values.payment_proof_details.size = values.payment_proof.size;
      } else {
        values.payment_proof = "";
      }

      let status = await getsettlementPaymentStatus(values);
      values.status = status;

      let dueAmount = getDueAmount();
      let writeoff = +dueAmount - values.settlement_amount;
      if (writeoff === 0) {
        values.settlement_status = "full_settled";
      }

      onformSubmit(values);
    },
  });

  return (
    <>
      <Row className="mb-5">
        <Col>
          <BuyerSnippet defaulter={defaulter} />
        </Col>
        <Col>
          <SupplierSnippet defaulter={defaulter} />
        </Col>
      </Row>
      <Row>
        <Col>
          <form
            onSubmit={formik.handleSubmit}
            method="post"
            encType="multipart/form-data"
          >
            <Row>
              <Col md={6}>
                <div className="form-floating">
                  <input
                    name="payment_due_amount"
                    id="payment_due_amount"
                    type="number"
                    value={formik.values.payment_due_amount}
                    onChange={formik.handleChange}
                    className="form-control"
                    placeholder="Reported Amount"
                    size="sm"
                    readOnly
                  />
                  <label htmlFor="payment_due_amount">Reported Amount</label>
                </div>
                {formik.touched.reported_amount &&
                formik.errors.reported_amount ? (
                  <span className="error text-danger">
                    {formik.errors.reported_amount}
                  </span>
                ) : null}
              </Col>
              <Col md={6}>
                <div className="form-floating">
                  <input
                    name="paid_amount"
                    id="paid_amount"
                    type="number"
                    value={formik.values.paid_amount}
                    onChange={formik.handleChange}
                    className="form-control"
                    placeholder="Paid Amount"
                    size="sm"
                    readOnly
                  />
                  <label htmlFor="paid_amount">Paid Amount</label>
                </div>
                {formik.touched.paid_amount && formik.errors.paid_amount ? (
                  <span className="error text-danger">
                    {formik.errors.paid_amount}
                  </span>
                ) : null}
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={6}>
                <div className="form-floating">
                  <input
                    name="balance_amount"
                    id="balance_amount"
                    type="number"
                    value={formik.values.balance_amount}
                    onChange={formik.handleChange}
                    className="form-control"
                    placeholder="Balance Amount"
                    size="sm"
                    readOnly
                  />
                  <label htmlFor="balance_amount">Balance Amount</label>
                </div>
                {formik.touched.balance_amount &&
                formik.errors.balance_amount ? (
                  <span className="error text-danger">
                    {formik.errors.balance_amount}
                  </span>
                ) : null}
              </Col>
              <Col md={6}>
                <div className="form-floating">
                  <input
                    name="payment_due_days"
                    id="payment_due_days"
                    value={formik.values.payment_due_days}
                    onChange={formik.handleChange}
                    type="number"
                    className="form-control"
                    placeholder="Payment due days"
                    size="sm"
                    readOnly
                  />
                  <label htmlFor="payment_due_days">Payment due days</label>
                </div>
                {formik.touched.payment_due_days &&
                formik.errors.payment_due_days ? (
                  <span className="error text-danger">
                    {formik.errors.payment_due_days}
                  </span>
                ) : null}
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={6}>
                <div className="form-floating">
                  <select
                    name="settlement_status"
                    id="settlement_status"
                    className="form-select"
                    value={formik.values.settlement_status}
                    onChange={settlementStatusChangeHandler}
                  >
                    <option value="">select</option>
                    <option value="partial_settled">Partial Settled</option>
                    <option value="full_settled">Full Settled</option>
                  </select>
                  <label htmlFor="settlement_status">Settlement Status</label>
                </div>
                {formik.touched.settlement_status &&
                formik.errors.settlement_status ? (
                  <span className="error text-danger">
                    {formik.errors.settlement_status}
                  </span>
                ) : null}
              </Col>
              <Col md={6}>
                <div className="form-floating">
                  <input
                    name="settlement_amount"
                    id="settlement_amount"
                    type="number"
                    className="form-control"
                    placeholder="Enter settlement amount"
                    value={formik.values.settlement_amount}
                    size="sm"
                    onChange={settlementAmountChangeHandler}
                    readOnly={!formik.values.settlement_status ? true : false}
                  />
                  <label htmlFor="settlement_amount">Settlement Amount</label>
                </div>
                {formik.touched.settlement_amount &&
                formik.errors.settlement_amount ? (
                  <span className="error text-danger">
                    {formik.errors.settlement_amount}
                  </span>
                ) : null}
                {!isCommissionSlabExist && (
                  <span className="error text-danger">
                    Commission slab does not exist for this amount.
                  </span>
                )}
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={6}>
                <div className="form-floating">
                  <input
                    name="writeoff_amount"
                    id="writeoff_amount"
                    type="number"
                    className="form-control"
                    placeholder="Writeoff amount"
                    size="sm"
                    value={writeoffAmount}
                    onChange={formik.handleChange}
                    readOnly
                  />
                  <label htmlFor="writeoff_amount">Writeoff amount</label>
                </div>
              </Col>
              <Col md={6}>
                <div className="form-floating">
                  <input
                    name="service_charge_percentage"
                    type="number"
                    className="form-control"
                    placeholder="Service charge"
                    value={serviceChargePercentage}
                    size="sm"
                    onChange={formik.handleChange}
                    disabled={true}
                  />
                  <label htmlFor="service_charge_percentage">
                    Service charge(%)
                  </label>
                </div>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={6}>
                <div className="form-floating">
                  <input
                    name="service_charge_amount"
                    id="service_charge_amount"
                    type="number"
                    className="form-control"
                    placeholder="Service charge amount"
                    value={serviceChargeAmount}
                    size="sm"
                    onChange={formik.handleChange}
                    readOnly
                  />
                  <label htmlFor="service_charge_amount">
                    Service charge amount
                  </label>
                </div>
              </Col>
              <Col md={6}>
                {paidTo === "supplier" && (
                  <div className="form-floating">
                    <select
                      name="dispute_matter"
                      id="dispute_matter"
                      className="form-select"
                      value={disputeMatter}
                      onChange={disputeStatusHandler}
                    >
                      <option value="1">Yes</option>
                      <option value="0">No</option>
                    </select>
                    <label htmlFor="dispute_matter">Dispute Matter?</label>
                  </div>
                )}
                {paidTo === "creditq" && (
                  <div className="form-floating">
                    <input
                      name="service_charge_gst"
                      type="number"
                      className="form-control"
                      placeholder="Service Charge GST"
                      value={serviceChargeGst}
                      size="sm"
                      readOnly={true}
                      onChange={formik.handleChange}
                    />
                    <label htmlFor="service_charge_gst">
                      GST on Service charge
                    </label>
                  </div>
                )}
              </Col>
            </Row>

            <Row className="mt-3">
              <Col md={6}>
                <div className="form-floating">
                  <select
                    name="noc_signed_status"
                    id="noc_signed_status"
                    className="form-select"
                    value={formik.values.noc_signed_status}
                    onChange={formik.handleChange}
                    disabled
                  >
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                  </select>
                  <label htmlFor="noc_signed_status">NOC signed status</label>
                </div>
              </Col>
              <Col md={6}>
                {paidTo === "supplier" && (
                  <div className="form-floating">
                    <input
                      name="discount_offered"
                      id="discount_offered"
                      type="number"
                      className="form-control"
                      placeholder="Discount offered"
                      value={formik.values.discount_offered}
                      size="sm"
                      onChange={(event) =>
                        disputeDiscountHandler(event.target.value)
                      }
                      readOnly={!isDiscountAllowed() ? true : false}
                    />
                    <label htmlFor="discount_offered">
                      Discount Offered (%)
                    </label>
                  </div>
                )}
                {formik.touched.discount_offered &&
                formik.errors.discount_offered ? (
                  <span className="error text-danger">
                    {formik.errors.discount_offered}
                  </span>
                ) : null}
              </Col>
            </Row>

            {paidTo === "supplier" && (
              <Row className="mt-3">
                <Col md={6}>
                  {paidTo === "supplier" && (
                    <div className="form-floating">
                      <input
                        name="service_charge_gst"
                        type="number"
                        className="form-control"
                        placeholder="Service Charge GST"
                        value={serviceChargeGst}
                        size="sm"
                        readOnly={true}
                        onChange={formik.handleChange}
                      />
                      <label htmlFor="service_charge_gst">
                        GST on Service charge
                      </label>
                    </div>
                  )}
                </Col>

                <Col md={6}>
                  <div className="form-floating">
                    <input
                      name="service_charge_amount_after_discount"
                      id="service_charge_amount_after_discount"
                      type="number"
                      className="form-control"
                      placeholder="Service charge amount After Discount"
                      value={
                        !isDiscountAllowed()
                          ? 0
                          : serviceChargeAmountAfterDiscount
                      }
                      size="sm"
                      onChange={formik.handleChange}
                      readOnly
                    />
                    <label htmlFor="service_charge_amount_after_discount">
                      Service charge amount After Discount
                    </label>
                  </div>
                </Col>
              </Row>
            )}
            <Row className="mt-3">
              <Col md={6}>
                <div className="form-floating">
                  <input
                    name="total_payable_amount"
                    id="total_payable_amount"
                    type="number"
                    className="form-control"
                    placeholder="Total Payable Amount"
                    size="sm"
                    value={totalPayableAmount}
                    readOnly
                  />
                  <label htmlFor="total_payable_amount">
                    Total Payable Amount
                  </label>
                </div>
              </Col>
              <Col md={6}>
                <div className="form-floating">
                  {paidTo === "creditq" ? (
                    <input
                      name="service_charge_received"
                      id="service_charge_received"
                      type="text"
                      className="form-control"
                      placeholder="service charge received"
                      size="sm"
                      value={formik.values.service_charge_received}
                      readOnly
                    />
                  ) : (
                    <div>
                      <select
                        name="service_charge_received"
                        id="service_charge_received"
                        className="form-select"
                        value={formik.values.service_charge_received}
                        onChange={formik.handleChange}
                      >
                        <option value="">select</option>
                        <option value="Due">Due</option>
                        <option value="Paid">Paid</option>
                      </select>
                      <label htmlFor="service_charge_received">
                        Service Charge Received Status
                      </label>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={6}>
                <div className="form-floating">
                  <Form.Group controlId="formFile" className="mb-3">
                    <label htmlFor="payment_proof">Payment Proof</label>
                    <Form.Control
                      type="file"
                      disabled={
                        formik.values.service_charge_received === "Paid"
                          ? false
                          : true
                      }
                      name="payment_proof"
                      onChange={(event) => {
                        console.log(
                          "file data###############",
                          event.currentTarget.files[0]
                        );
                        formik.setFieldValue(
                          "payment_proof",
                          event.currentTarget.files[0]
                        );
                        _selectFiles(event);
                      }}
                    />
                  </Form.Group>
                </div>
                {formik.touched.payment_proof && formik.errors.payment_proof ? (
                  <span className="error text-danger">
                    {formik.errors.payment_proof}
                  </span>
                ) : null}
              </Col>
              <Col md={6}>
                <div className="form-floating">
                  {formik.values.service_charge_received === "Paid" ? (
                    <input
                      name="invoice_type"
                      id="invoice_type"
                      type="text"
                      className="form-control"
                      size="sm"
                      value="Tax Invoice"
                      readOnly
                    />
                  ) : (
                    <select
                      name="invoice_type"
                      id="invoice_type"
                      className="form-select"
                      onChange={formik.handleChange}
                    >
                      <option value="">select</option>
                      <option value="Performa Invoice">Performa Invoice</option>
                      <option value="Advance Tax invoice">
                        Advance Tax invoice
                      </option>
                    </select>
                  )}
                  <label htmlFor="invoice_type">Invoice Type</label>
                </div>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col>
                <div className="form-floating">
                  <textarea
                    name="remarks"
                    id="remarks"
                    className="form-control"
                    placeholder="Enter your remark"
                    size="sm"
                    style={{ minHeight: "150px" }}
                    onChange={formik.handleChange}
                  ></textarea>
                  <label htmlFor="total_payable_amount">Agent Remarks</label>
                </div>
                {formik.touched.remarks && formik.errors.remarks ? (
                  <span className="error text-danger">
                    {formik.errors.remarks}
                  </span>
                ) : null}
              </Col>
            </Row>

            <Row>
              <Col>
                <div>
                  <p style={{ marginTop: "20px" }}>invoice Verification</p>
                  <ServicesChargesInvoice
                    defaulter={defaulter}
                    settlementAmount={settlementAmount}
                    writeoffAmount={writeoffAmount}
                    serviceChargePercentage={serviceChargePercentage}
                    serviceChargeAmount={serviceChargeAmount}
                    totalPayableAmount={totalPayableAmount}
                    serviceChargeGst={serviceChargeGst}
                    disputeMatter={disputeMatter}
                    discountOffered={serviceChargeDiscount}
                    settlementStatus={formik.values.settlement_status}
                  />
                </div>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col className="mt-3 text-center">
                <Link
                  className="btn btn-secondary me-5"
                  size="lg"
                  to={backButtonLink}
                >
                  Back
                </Link>
                <Button
                  variant="primary"
                  className="ms-5"
                  size="lg"
                  type="submit"
                  disabled={isDisabled}
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </form>
        </Col>
      </Row>
    </>
  );
};

export default SettlementPaymentForm;
