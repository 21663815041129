import React, { useState, useEffect } from "react";
import "./style.css";
import { Field, Form, Formik } from "formik";
import Select from "react-select";
import * as Yup from "yup";
import defaultersService from "../../services/payments";
const ContactVerificationModal = (props) => {
  const { selectedEntry, setIsOpen,refreshPage } = props;
  const [verificationStatus, setVerificationStatus] = useState("");
  const [values, setValues] = useState({
    status: "",
    rejectionReason: "",
  });
  const statusOptions = [
    {
      value: "VERIFIED",
      label: "Verify",
    },
    { value: "REJECTED", label: "Reject" },
  ];
  let validationSchema = Yup.object().shape({
    status: Yup.string().trim().required().label("Status"),
    rejectionReason: Yup.string()
      .trim()
      .when("status", {
        is: "REJECTED",
        then: Yup.string().required(),
        otherwise: Yup.string(),
      })
      .label("Reason"),
  });
  const initialValues = values;
  const handleEnteredValues = async (value) => {
    value.updatedEntry = selectedEntry
    const result =
      await defaultersService.updateDefaulterContactVerificationStatus(value);
    refreshPage()
    setIsOpen(false);
  };
  return (
    <div>
      <h5 style={{ color: "#15141469", fontWeight: "bold" }}>
        DEFAULTER VERIFICATION
      </h5>
      <Formik
        onSubmit={async (values, { setSubmitting }) => {
          handleEnteredValues(values);
          setSubmitting(false);
        }}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={validationSchema}
        initialValues={initialValues}
      >
        {({ isSubmitting, errors, touched, setFieldValue, values }) => (
          <Form>
            
            <div className="form-box-mobile">
              <label>Defaulter Name</label>
              <p>{selectedEntry.defaulterTradeName}</p>
              <label>Supplier Name</label>
              <p>{selectedEntry.supplierTradeName}</p>
              <label>Defaulter Number To Verify</label>
              <p>{selectedEntry.contact_value}</p>
              <label>Update Status</label>
              <Select
                defaultValue={verificationStatus}
                value={verificationStatus}
                name="status"
                onChange={(e) => {
                  setFieldValue("status", e.value);
                  setVerificationStatus(e);
                }}
                options={statusOptions}
              />
              {errors.status && touched.status && (
                <span className="error">{errors.status}</span>
              )}
            </div>
            {verificationStatus && verificationStatus.value === "REJECTED" && (
              <div className="form-box-mobile">
                <label>Reason</label>
                <Field
                  name="rejectionReason"
                  type="text"
                  onChange={(e) => {
                    setFieldValue("rejectionReason", e.target.value);
                  }}
                />
                {errors.rejectionReason && touched.rejectionReason && (
                  <span className="error">{errors.rejectionReason}</span>
                )}
              </div>
            )}

            <div className="flex mt20">
              <button
                type="submit"
                disabled={isSubmitting}
                className="btn-theme mlauto"
              >
                Submit
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ContactVerificationModal;
