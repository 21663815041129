import * as API_URL from "../../constants/api";
import * as apiService from "../api";
import { getToken } from "../../helpers/common";

const getDefaulters = async (page, filterData) => {
  try {
    let data = {};

    let token = getToken();

    //if (gstn) {console
    data = { page, filterData };
    console.log("data", data);
    let result = await apiService.post(API_URL.GET_DEFAULTERS, data, token);
    return result;
    // }
  } catch (e) {
    return { status: false, data: {}, message: e.message };
  }
};

const getDefaulter = async (filterData) => {
  try {
    let data = {};

    let token = getToken();

    //if (gstn) {console
    data = { filterData };
    //console.log("data", data);
    let result = await apiService.post(API_URL.GET_DEFAULTER, data, token);
    return result;
    // }
  } catch (e) {
    return { status: false, data: {}, message: e.message };
  }
};
const getSupplier = async (filterData) => {
  try {
    let data = {};

    let token = getToken();

    //if (gstn) {console
    data = { filterData };
    console.log("###############data", data);
    console.log("API_URL.GET_SUPPLIER", API_URL.GET_SUPPLIER);
    let result = await apiService.post(API_URL.GET_SUPPLIER, data, token);
    console.log("#######result", result);
    return result;
    // }
  } catch (e) {
    return { status: false, data: {}, message: e.message };
  }
};

const settleDefaulter = async (formValue) => {
  try {
    let data = {};

    let token = getToken();

    //if (gstn) {console
    data = { formValue };
    //console.log("data", data);
    let result = await apiService.post(API_URL.SETTLE_DEFAULTER, data, token);
    return result;
    // }
  } catch (e) {
    return { status: false, data: {}, message: e.message };
  }
};

const saveDefaulterContacts = async (postData) => {
  try {
    let token = getToken();

    let result = await apiService.post(
      API_URL.SETTLE_DEFAULTER_CONTACTS_SAVE,
      postData,
      token
    );
    return result;
    // }
  } catch (e) {
    return { status: false, data: {}, message: e.message };
  }
};

const resendDueOffer = async (formValue) => {
  try {
    let data = {};

    let token = getToken();

    //if (gstn) {console
    data = { formValue };
    //console.log("data", data);
    let result = await apiService.post(
      API_URL.RESEND_DUE_OFFER_EMAIL_TO_DEFAULTER,
      data,
      token
    );

    return result;
    // }
  } catch (e) {
    return { status: false, data: {}, message: e.message };
  }
};
const settledHistory = async (formValue) => {
  try {
    console.log("formValue#####", formValue);
    let data = {};

    let token = getToken();
    data = { formValue };

    let result = await apiService.post(
      API_URL.GET_SETTLED_HISTORY,
      data,
      token
    );

    return result;
  } catch (e) {
    return { status: false, data: {}, message: e.message };
  }
};
const getRoleAccess = async () => {
  try {
    let token = getToken();

    let result = await apiService.get(API_URL.GET_ACCESS_PERMISSION, token);
    return result;
    // }
  } catch (e) {
    return { status: false, data: {}, message: e.message };
  }
};
const getCQSupplier = async(data) => {
  try{
    const token = getToken();
    const result = await apiService.post(API_URL.GET_CQ_SUPPLIER,data, token);
    return result
  }catch(error){
    console.log("error in getCQSupplier: ", error)
  }
}

const defaultersService = {
  getDefaulters,
  getDefaulter,
  settleDefaulter,
  saveDefaulterContacts,
  resendDueOffer,
  settledHistory,
  getSupplier,
  getRoleAccess,
  getCQSupplier,
};

export default defaultersService;
