import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";
import PaymentsService from "../../services/payments";
import { toast } from "react-toastify";

export const getPayment = createAsyncThunk(
  "payments/getPayment",
  async (id, thunkAPI) => {
    try {
      const result = await PaymentsService.getPayment(id);
      console.log("payment details###########", result);
      if (result.status) {
        return result;
      } else {
        thunkAPI.dispatch(setMessage(result.data.message));
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const getCallHoldRequestById = createAsyncThunk(
  "payments/CallHoldRequestById",
  async (id, thunkAPI) => {
    try {
      console.log("this is id ", id);
      const result = await PaymentsService.getCallHoldFetchOne(id);
      console.log("payment details###########", result);
      if (result.status) {
        return result;
      } else {
        thunkAPI.dispatch(setMessage(result.data.message));
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getPayments = createAsyncThunk(
  "payments/getPayments",
  async (filterData, thunkAPI) => {
    try {
      console.log("filterData#########", filterData);
      const result = await PaymentsService.getPayments(filterData);
      console.log("filterData######### result - payment list#########", result);
      if (result.status) {
        return result;
      } else {
        thunkAPI.dispatch(setMessage(result.data.message));
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const savePayment = createAsyncThunk(
  "payments/savePayment",
  async (payload, thunkAPI) => {
    try {
      const result = await PaymentsService.savePayment(payload);
      if (result.status) {
        toast.success(result.message);
        return result;
      } else {
        thunkAPI.dispatch(setMessage(result.data.message));
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const saveCallHold = createAsyncThunk(
  "pcallHold/saveCallHold",
  async (payload, thunkAPI) => {
    try {
      const result = await PaymentsService.saveCallHold(payload);
      if (result.status) {
        toast.success(result.message);
        return result;
      } else {
        thunkAPI.dispatch(setMessage(result.data.message));
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const getCallHoldRequest = createAsyncThunk(
  "payments/callHoldRequest",
  async (filterData, thunkAPI) => {
    try {
      console.log("filterData#########-12", filterData);
      const result = await PaymentsService.getCallHoldRequest(filterData);
      console.log(
        "filterData######### result - getCallHoldRequest list#########",
        result
      );
      if (result.status) {
        return result;
      } else {
        thunkAPI.dispatch(setMessage(result.data.message));
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updatePayment = createAsyncThunk(
  "payments/updatePayment",
  async (payload, thunkAPI) => {
    try {
      const result = await PaymentsService.updatePayment(payload);
      if (result.status) {
        toast.success(result.message);
        return result;
      } else {
        thunkAPI.dispatch(setMessage(result.data.message));
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateCallRequest = createAsyncThunk(
  "payments/updateCallRequest",
  async (payload, thunkAPI) => {
    try {
      console.log("payload-----", payload);
      const result = await PaymentsService.updateCallRequestHold(payload);
      if (result.status) {
        toast.success(result.message);
        return result;
      } else {
        thunkAPI.dispatch(setMessage(result.data.message));
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = {
  paymentDetails: {},
  payments: [],
  newPaymentAdded: false,
  refreshList: false,
  paymentDetailsLoaded: false,
};

const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    resetnewPaymentAdded(state, action) {
      state.newPaymentAdded = false;
      state.paymentDetailsLoaded = false;
    },
  },
  extraReducers: {
    [getPayment.fulfilled]: (state, action) => {
      console.log("##getPayment.fulfilled##", action);
      state.paymentDetails = action.payload;
      state.paymentDetailsLoaded = true;
    },
    [getPayment.rejected]: (state, action) => {
      console.log("##getPayment.rejected##", action);
      state.paymentDetails = null;
    },

    [getCallHoldRequestById.fulfilled]: (state, action) => {
      console.log("##getCallHoldRequestById.fulfilled##", action);
      state.holdRequestDetails = action.payload;
      state.holdRequestDetailsLoaded = true;
    },
    [getCallHoldRequestById.rejected]: (state, action) => {
      console.log("##getCallHoldRequestById.rejected##", action);
      state.holdRequestDetails = null;
    },
    [getPayments.fulfilled]: (state, action) => {
      console.log("##getPayments.fulfilled##", action);
      state.payments = action.payload;
    },
    [getPayments.rejected]: (state, action) => {
      console.log("##getPayments.rejected##", action);
      state.payments = null;
    },

    [savePayment.fulfilled]: (state, action) => {
      console.log("##savePayment.fulfilled##", action);
      state.newPaymentAdded = true;
    },
    [savePayment.rejected]: (state, action) => {
      console.log("##savePayment.rejected##", action);
      // state.payments = action.payload;
    },

    [saveCallHold.fulfilled]: (state, action) => {
      console.log("##savePayment.fulfilled##", action);
      state.newCallHoldAdded = true;
    },
    [saveCallHold.rejected]: (state, action) => {
      console.log("##savePayment.rejected##", action);
      // state.payments = action.payload;
    },
    [getCallHoldRequest.fulfilled]: (state, action) => {
      console.log("##getPayments.fulfilled##", action);
      state.callRequests = action.payload;
    },
    [getCallHoldRequest.rejected]: (state, action) => {
      console.log("##getPayments.rejected##", action);
      state.callRequests = null;
    },
    [updatePayment.fulfilled]: (state, action) => {
      console.log("##updatePayment.fulfilled##", action);
      state.refreshList = !state.refreshList;
    },
    [updatePayment.rejected]: (state, action) => {
      console.log("##updatePayment.rejected##", action);
      //state.payments = action.payload;
    },
    [updateCallRequest.fulfilled]: (state, action) => {
      console.log("##updatePayment.fulfilled##", action);
      state.refreshList = !state.refreshList;
    },
    [updateCallRequest.rejected]: (state, action) => {
      console.log("##updatePayment.rejected##", action);
      //state.payments = action.payload;
    },
  },
});
export const createPaymentActions = paymentSlice.actions;
export default paymentSlice.reducer;
