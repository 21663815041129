import { useEffect, useState } from "react";
import Layout from "../../layouts/default";
import { clearMessage } from "../../store/slices/message";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getDefaulter, getPermission } from "../../store/slices/defaulter";
import * as creditqDefaultersApi from "../../store/slices/creditqDefaulters/index";

import { useNavigate } from "react-router-dom";
import CallRequestForm from "../../components/CallRequestHold/CallRequestForm";
import { saveCallHold, createPaymentActions } from "../../store/slices/payment";

const CallRequestCreate = () => {
  const { message } = useSelector((state) => state.messageSlice);
  const { defaulter } = useSelector((state) => state.defaulterSlice);
  const { creditqDefaulter } = useSelector(
    (state) => state.creditqDefaultersSlice
  );
  const { newCallHoldAdded } = useSelector((state) => state.paymentSlice);
  const { permission } = useSelector((state) => state.defaulterSlice);
  

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { defaulter_id, holdType: holdType, source } = useParams();
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    dispatch(clearMessage());
    dispatch(createPaymentActions.resetnewPaymentAdded());
    const fetchDefaulter = async (id) => {
      if (id > 0) {
        let filterData = { id: Number(id), commission: true };
        dispatch(creditqDefaultersApi.getDefaulter({ filterData }));
      }
      dispatch(getPermission());
    };

    fetchDefaulter(defaulter_id);
  }, []);

  useEffect(() => {
    const fetchDefaulter = async (id) => {
      if (id > 0) {
        let filterData = { id: Number(id) };
        dispatch(getDefaulter({ filterData }));
        dispatch(getPermission());
      }
    };
    fetchDefaulter(defaulter_id);
  }, [defaulter_id]);

  useEffect(() => {
    if (newCallHoldAdded) {
      if (source === "creditqDefaulter") {
        navigate(`/creditq-defaulters/${defaulter_id}`);
      } else {
        navigate(`/defaulter/${defaulter_id}`);
      }
    }
  }, [newCallHoldAdded, defaulter_id, navigate, source]);

  const pageTitle = `Call request hold for - ${holdType}`;

  const formSubmitHandler = (payload) => {
    try {
      console.log("payload##########", payload);
      setIsDisabled(true);
      dispatch(saveCallHold(payload)).then(() => {
        setIsDisabled(false);
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Layout heading={pageTitle}>
      {message && <div className="alert alert-success">{message}</div>}
      {
        <CallRequestForm
          onformSubmit={formSubmitHandler}
          holdFor={holdType}
          defaulter={
            source === "creditqDefaulter"
              ? creditqDefaulter?.data?.defaulter
              : defaulter?.data?.defaulter
          }
          contacts={
            source === "creditqDefaulter"
              ? creditqDefaulter?.data?.contacts
              : defaulter?.data?.contacts
          }
          isDisabled={isDisabled}
          permission={permission}
          source={source}
        />
      }
    </Layout>
  );
};

export default CallRequestCreate;
