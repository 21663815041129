import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Table } from "react-bootstrap";
import { format } from "date-fns";
import Classes from "./style.module.css";

export const CallRequestHoldList = (props) => {
  const { callRequests, defaulter_id, defaulter_case_id, source } = props;

  const _tableHeader = () => {
    return (
      <thead>
        <tr>
          <th>Hold Type</th>
          <th>Hold From</th>
          <th>Hold To</th>
          <th>Installment Amount</th>
          <th>created By</th>
          <th>Date Created</th>
          <th>Status</th>
          <th>Actions</th>
        </tr>
      </thead>
    );
  };

  const _itemsList = () => {
    if (!callRequests?.data?.response.length) {
      return (
        <tr>
          <td colSpan="8">No record found!!</td>
        </tr>
      );
    }
    // console.log("################payment", callRequests);
    return callRequests.data.response.map((item, index) => {
      // console.log("service charge item list", item);
      return (
        <tr key={item.id}>
          <td>{item.type_for_hold}</td>
          <td>{item.hold_from}</td>
          <td>{item.hold_to}</td>
          <td>{item.installment_amount}</td>
          <td>{item.name + `(` + item.mobile + `)`}</td>
          <td>{format(new Date(item.date_created), "yyyy-MM-dd")}</td>
          <td>{item.request_status}</td>
          <td className={Classes.actions}>
            <Link
              to={`/holdRequest/${item.type_for_hold}/${defaulter_case_id}/edit/${item.id}/${source}`}
            >
              {item.request_status === "pending" ? "Edit" : "View"}
            </Link>
          </td>
        </tr>
      );
    });
  };

  return (
    <>
      <Table className="striped bordered  hover">
        {_tableHeader()}
        <tbody>{_itemsList()}</tbody>
      </Table>
    </>
  );
};
export default CallRequestHoldList;
