import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Table } from "react-bootstrap";
import { format } from "date-fns";
import CreditqDefaulters from "../services/creditqDefaulters/index";
import { getToken } from "../helpers/common";
import moment from "moment-timezone";

export const SettlementPaymentHistory = (props) => {
  const { payments, defaulter_id } = props;

  const _tableHeader = () => {
    return (
      <thead>
        <tr>
          <th>Payment Received Date</th>
          <th>Settlement Entry Date</th>
          <th>Action Date</th>
          <th>Settlement Amount</th>
          <th>Settlement Type</th>
          <th>Status</th>
          <th>Evidence</th>
        </tr>
      </thead>
    );
  };

  const handleDownloadButton = async (file) => {
    const token = getToken();
    // const fileExtension = file.split(".").pop();
    const payload = {
      filename: `paymentproof`,
      filepath: file,
    };
    await CreditqDefaulters.downloadPaymentProof(payload, token);
  };

  const _itemsList = () => {
    if (!payments?.data?.length) {
      return (
        <tr>
          <td colSpan="8">No record found!!</td>
        </tr>
      );
    }
    return payments.data.map((item, index) => {
      return (
        <tr key={item.id}>
          {item.transaction_date ? (
            <td>{moment.utc(item.transaction_date).format("YYYY-MM-DD")}</td>
          ) : (
            <td>_</td>
          )}

          <td>{moment.utc(item.date_created).format("YYYY-MM-DD HH:mm")}</td>
          <td>
            {item.source !== "customer"
              ? item.final_approval_status === "pending"
                ? item.l1_approval_date &&
                  moment.utc(item.l1_approval_date).format("YYYY-MM-DD HH:mm")
                : item.final_approval_date &&
                  moment
                    .utc(item.final_approval_date)
                    .format("YYYY-MM-DD HH:mm")
              : "-"}
          </td>
          <td>{item.amount ? item.amount : ""}</td>
          <td>{item.settlement_type}</td>
          <td>
            {item.source !== "customer"
              ? item.final_approval_status !== "pending"
                ? item.final_approval_status
                : item.l1_approval_status !== "PENDING"
                ? item.l1_approval_status === "REJECTED"
                  ? "Rejected"
                  : "Waiting for higher approval"
                : "Pending"
              : "-"}
          </td>
          {item.evidence_filename ? (
            <td
              onClick={() => handleDownloadButton(item.evidence_filename)}
              style={{ cursor: "pointer", color: "#007bff" }}
            >
              {"Download"}
            </td>
          ) : (
            <td>_</td>
          )}
          {/* <td className={Classes.actions}>
            <Link
              to={`/payment/${item.paid_to}/${defaulter_case_id}/edit/${item.id}`}
            >
              {item.status === "draft" || item.status === "pending_for_approval"
                ? "Edit"
                : "View"}
            </Link>
          </td> */}
        </tr>
      );
    });
  };

  return (
    <>
      <Table className="minimalistic-table">
        {_tableHeader()}
        <tbody>{_itemsList()}</tbody>
      </Table>
    </>
  );
};
export default SettlementPaymentHistory;
