import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";
import settlementRequestService from "../../services/settlementRequests";

export const getSettlementRequests = createAsyncThunk(
  "settlementRequests/getSettlementRequests",
  async ({ page, filterData }, thunkAPI) => {
    try {
      const result = await settlementRequestService.getSettlementRequests(
        page,
        filterData
      );
      if (result.status) {
        return result;
      } else {
        thunkAPI.dispatch(setMessage(result.data.message));
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = { settlementRequests: null };

const settlementRequestsSlice = createSlice({
  name: "settlementRequests",
  initialState,

  extraReducers: {
    [getSettlementRequests.fulfilled]: (state, action) => {
      state.settlementRequests = action.payload.data;
    },
    [getSettlementRequests.rejected]: (state, action) => {
      state.settlementRequests = null;
    },
  },
});

export default settlementRequestsSlice.reducer;
