import { Nav, Navbar } from "react-bootstrap";
import styled from "styled-components";
import breakpoints from "../../styles/breakpoints";
import { Link } from "react-router-dom";
import * as Icon from "react-bootstrap-icons";

// /*background-color: ${(props) => props.theme.primary};*/
/*export const NavbarHeader = styled(Navbar.Header)``;*/
export const Sidebar = styled.div`
  position: relative;
  padding: 0 15px;
  background-color: #f4f5f7;
  color: rgb(66, 82, 110);
  height: 100vh;
  border-right-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  @media only screen and ${breakpoints.sm} {
    height: auto;
    width: 100%;
    z-index: 1000;
    background-color: #ffffff;
  }
`;

export const NavbarToggle = styled(Navbar.Toggle)`
  margin-right: 10px;
  @media only screen and ${breakpoints.sm} {
    position: absolute;
    top: -50px;
    left: 0;
  }
`;
export const NavWrapper = styled(Nav)`
  display: block;
  margin-top: 30px;
  width: 100%;
`;

export const NavItem = styled(Navbar.Brand)`
  display: block;
  width: 100%;
  padding: 2px 0 !important;
`;

export const NavLink = styled(Link)`
  display: block;
  color: #9d9f9e !important;
  text-decoration: none;
  padding: 5px;
  width: 100%;
  font-size: 14px;

  &.activeClass {
    color: rgb(66, 82, 110) !important;
    background-color: rgb(235, 236, 240);
    border-radius: 5px;
  }
  &:hover {
    color: rgb(66, 82, 110) !important;
    background-color: rgb(235, 236, 240);
    border-radius: 5px;
  }
`;

export const Navigationbar = styled(Navbar)`
  padding: 2px;
  display: block;
  position: relative;
`;

export const LeftArrowCircle = styled(Icon.ArrowLeftCircle)`
  position: absolute;
  top: 20px;
  right: -9px;
  width: 25px;
  height: 25px;
  cursor: pointer;
  &:hover {
    color: #0d6efd;
  }
`;
