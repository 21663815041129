import React, {useState, useEffect} from "react";
import dateFormat from "date-fns/format";
import defaultersService from "../../services/payments";

const ServicesChargesInvoice = (props) => {
  const {
    defaulter,
    settlementAmount,
    writeoffAmount,
    serviceChargePercentage,
    serviceChargeAmount,
    totalPayableAmount,
    serviceChargeGst,
    disputeMatter,
    discountOffered,
    settlementStatus,
    invoice_id = false,
  } = props;
  const [buyerTradeName, setBuyerTradeName] = useState("");
  const [buyerGst, setBuyerGst] = useState("");
  const [defaulterVerifiedDate, setDefaulterVerifiedDate] = useState("");
  const [discountAmount, setDiscountAmount] = useState("");
  const [cashbackAmount, setCashbackAmount] = useState("");
  const [cgstTax, setCgstTax] = useState("");
  const [sgstTax, setSgstTax] = useState("");
  const [igstTax, setIgstTax] = useState("");
  const [cgstTaxAmount, setCgstTaxAmount] = useState("");
  const [sgstTaxAmount, setSgstTaxAmount] = useState("");
  const [igstTaxAmount, setIgstTaxAmount] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [serviceChargeSettlementCashback, setServiceChargeSettlementCashback] =
    useState(0);

  useEffect(() => {
    _getSettlementCashback();
  }, []);
  const _getSettlementCashback = async () => {
    let settlementCashbackResult =
      await defaultersService.getSettlementCashback(defaulter.supplier_user_id);
    let cashback_amount = 0;
    console.log(
      "settlementCashbackResult.discountAmount#####",
      settlementCashbackResult.data[0].cashback_amount
    );
    if (settlementCashbackResult.status) {
      let settlementReqInvoiceId = settlementCashbackResult.data[0].invoice_id;
      let paymentStatus = settlementCashbackResult.data[0].payment_status;
      if (settlementReqInvoiceId) {
        if (invoice_id == settlementReqInvoiceId) {
          cashback_amount = settlementCashbackResult.data[0].cashback_amount
            ? parseInt(settlementCashbackResult.data[0].cashback_amount)
            : 0;
        }
      } else {
        if (paymentStatus == "due") {
          cashback_amount = settlementCashbackResult.data[0].cashback_amount
            ? parseInt(settlementCashbackResult.data[0].cashback_amount)
            : 0;
        }
      }
      setServiceChargeSettlementCashback(cashback_amount);
    }
  };

  useEffect(() => {
    console.log(
      "serviceSettlementAmount, writeoffAmount, servicePercentage, serviceChargeAmount,totalPayableAmount,serviceChargeGst,disputeMatter,serviceChargeDiscount",
      settlementAmount,
      writeoffAmount,
      serviceChargePercentage,
      serviceChargeAmount,
      totalPayableAmount,
      serviceChargeGst,
      disputeMatter,
      discountOffered
    );

    setBuyerTradeName(defaulter.buyer_trade_name);
    setBuyerGst(defaulter.buyer_gstn);
    setDefaulterVerifiedDate(
      dateFormat(new Date(defaulter.verified_date), "yyyy-MM-dd")
    );
  }, []);

  useEffect(() => {
    if (serviceChargeSettlementCashback != -1) {
      setServicesChargesData();
    }
  }, [props, serviceChargeSettlementCashback]);

  const setServicesChargesData = async () => {
    let discountAmount =
      (parseInt(serviceChargeAmount) * Number(discountOffered)) / 100;

    console.log("discountAmount#####", discountAmount);
    setDiscountAmount(discountAmount);

    let taxable_value = 0;
    let settlementCashback = 0;

    let serviceChargeAmountAfterDiscount = serviceChargeAmount - discountAmount;

    console.log(
      "serviceChargeAmountAfterDiscount#####",
      serviceChargeAmountAfterDiscount
    );
    console.log(
      "settlementCashbackAmount####",
      serviceChargeSettlementCashback
    );

    if (serviceChargeSettlementCashback > 0) {
      taxable_value =
        serviceChargeSettlementCashback > serviceChargeAmountAfterDiscount
          ? 0
          : serviceChargeAmountAfterDiscount - serviceChargeSettlementCashback;
      settlementCashback =
        serviceChargeSettlementCashback > serviceChargeAmountAfterDiscount
          ? serviceChargeAmountAfterDiscount
          : serviceChargeSettlementCashback;
    } else {
      taxable_value = serviceChargeAmountAfterDiscount;
    }
    setCashbackAmount(settlementCashback);

    let cgst = 0;
    let sgst = 0;
    let igst = 0;
    let supplierGst = defaulter.supplier_gstn;
    let firstTwoCharOfSupplierGst = supplierGst.slice(0, 2);
    if (firstTwoCharOfSupplierGst === "08") {
      cgst = 9;
      sgst = 9;
    } else {
      igst = 18;
    }
    setCgstTax(cgst);
    setSgstTax(sgst);
    setIgstTax(igst);

    let cgst_tax_amount = (taxable_value * cgst) / 100;
    setCgstTaxAmount(cgst_tax_amount);
    let sgst_tax_amount = (taxable_value * sgst) / 100;
    setSgstTaxAmount(sgst_tax_amount);
    let igst_tax_amount = (taxable_value * igst) / 100;
    setIgstTaxAmount(igst_tax_amount);

    let totalTax = cgst_tax_amount + sgst_tax_amount + igst_tax_amount;

    console.log("cashbackAmount####", cashbackAmount);
    let invoiceTotalAmount =
      serviceChargeSettlementCashback > serviceChargeAmountAfterDiscount
        ? 0
        : serviceChargeAmountAfterDiscount -
          serviceChargeSettlementCashback +
          totalTax;

    console.log("invoiceTotalAmount#####", invoiceTotalAmount);

    setTotalAmount(invoiceTotalAmount);
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "300px",
          overflowY: "scroll",
          overflowX: "scroll",
        }}
      >
        <table
          style={{
            borderCollapse: "collapse",
            width: "100%",
            borderTop: "1px solid",
          }}
        >
          <thead>
            <tr style={{borderBottom: "2px solid", borderColor: "gray"}}>
              <th style={{borderRight: "1px solid"}}>S. No.</th>
              <th style={{borderRight: "1px solid", width: "45.4%"}}>
                Particulars
              </th>
              <th style={{borderRight: "1px solid"}}>HSN/SAC</th>
              <th style={{borderRight: "1px solid"}}>Quantity</th>
              <th style={{borderRight: "1px solid"}}>Rate</th>
              <th style={{borderRight: "1px solid"}}>per</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr style={{borderBottom: "2px solid", borderColor: "gray"}}>
              <td style={{paddingLeft: "4px", borderRight: "1px solid"}}>1</td>
              <td
                style={{
                  paddingLeft: "5px",
                  borderRight: "1px solid",
                }}
              >
                <p>
                  <strong>Settlement Charges @ 18%</strong>
                </p>
                <p>
                  Settlement Charges Rs. <strong>{serviceChargeAmount}</strong>
                </p>
                <p>
                  ( {settlementAmount}* {serviceChargePercentage})
                </p>
                <p>
                  {" "}
                  {settlementStatus} with {buyerTradeName} ( {buyerGst})
                </p>
                <p>Deafulter Verify Date ({defaulterVerifiedDate})</p>
              </td>
              <td style={{borderRight: "1px solid"}}>9985</td>
              <td style={{borderRight: "1px solid"}}></td>
              <td style={{borderRight: "1px solid"}}></td>
              <td style={{borderRight: "1px solid"}}></td>
              <td style={{textAlign: "end"}}>{serviceChargeAmount}</td>
            </tr>

            {discountOffered > 0 && (
              <tr style={{borderBottom: "2px solid", borderColor: "gray"}}>
                <td style={{paddingLeft: "4px", borderRight: "1px solid"}}>
                  2
                </td>
                <td
                  style={{
                    paddingLeft: "5px",
                    borderRight: "1px solid",
                  }}
                >
                  <p>
                    <strong>Discount Allowed</strong>
                  </p>
                  <p>
                    ( {discountOffered} % on {serviceChargeAmount} )
                  </p>
                </td>
                <td style={{borderRight: "1px solid"}}></td>
                <td style={{borderRight: "1px solid"}}></td>
                <td style={{borderRight: "1px solid"}}></td>
                <td style={{borderRight: "1px solid"}}></td>
                <td style={{textAlign: "end"}}>{discountAmount}</td>
              </tr>
            )}

            {cashbackAmount > 0 && (
              <tr style={{borderBottom: "2px solid", borderColor: "gray"}}>
                <td style={{paddingLeft: "4px", borderRight: "1px solid"}}>
                  3
                </td>
                <td
                  style={{
                    paddingLeft: "5px",
                    borderRight: "1px solid",
                  }}
                >
                  <p>
                    <strong>Cashback Allowed</strong>
                  </p>
                  <p>( {cashbackAmount} )</p>
                </td>
                <td style={{borderRight: "1px solid"}}></td>
                <td style={{borderRight: "1px solid"}}></td>
                <td style={{borderRight: "1px solid"}}></td>
                <td style={{borderRight: "1px solid"}}></td>
                <td style={{textAlign: "end"}}> {cashbackAmount}</td>
              </tr>
            )}

            {cgstTaxAmount > 0 && (
              <tr>
                <td style={{borderRight: "1px solid", paddingTop: "12px"}}></td>
                <td
                  style={{
                    borderRight: "1px solid",
                    textAlign: "end",
                    paddingTop: "12px",
                  }}
                >
                  <strong>Output CGST @ 9%</strong>
                </td>
                <td style={{borderRight: "1px solid", paddingTop: "12px"}}></td>
                <td style={{borderRight: "1px solid", paddingTop: "12px"}}></td>
                <td
                  style={{
                    borderRight: "1px solid",
                    textAlign: "end",
                    paddingTop: "12px",
                  }}
                >
                  <strong>{cgstTax} </strong>
                </td>
                <td style={{borderRight: "1px solid", paddingTop: "12px"}}>
                  <strong>%</strong>
                </td>
                <td style={{textAlign: "end", paddingTop: "12px"}}>
                  <strong>{Math.round(cgstTaxAmount)}</strong>
                </td>
              </tr>
            )}

            {sgstTaxAmount > 0 && (
              <tr style={{borderBottom: "2px solid", borderColor: "gray"}}>
                <td style={{borderRight: "1px solid", paddingTop: "12px"}}></td>
                <td
                  style={{
                    borderRight: "1px solid",
                    textAlign: "end",
                    paddingTop: "12px",
                  }}
                >
                  <strong>Output SGST @ 9%</strong>
                </td>
                <td style={{borderRight: "1px solid", paddingTop: "12px"}}></td>
                <td style={{borderRight: "1px solid", paddingTop: "12px"}}></td>
                <td
                  style={{
                    borderRight: "1px solid",
                    textAlign: "end",
                    paddingTop: "12px",
                  }}
                >
                  <strong>{sgstTax}</strong>
                </td>
                <td style={{borderRight: "1px solid", paddingTop: "12px"}}>
                  <strong>%</strong>
                </td>
                <td style={{textAlign: "end", paddingTop: "12px"}}>
                  <strong> {Math.round(sgstTaxAmount)} </strong>
                </td>
              </tr>
            )}

            {igstTaxAmount > 0 && (
              <tr style={{borderBottom: "2px solid", borderColor: "gray"}}>
                <td style={{borderRight: "1px solid", paddingTop: "12px"}}></td>
                <td
                  style={{
                    borderRight: "1px solid",
                    textAlign: "end",
                    paddingTop: "12px",
                  }}
                >
                  <strong>Output IGST @ 18%</strong>
                </td>
                <td style={{borderRight: "1px solid", paddingTop: "12px"}}></td>
                <td style={{borderRight: "1px solid", paddingTop: "12px"}}></td>
                <td
                  style={{
                    borderRight: "1px solid",
                    textAlign: "end",
                    paddingTop: "12px",
                  }}
                >
                  <strong> {igstTax} </strong>
                </td>
                <td style={{borderRight: "1px solid", paddingTop: "12px"}}>
                  <strong>%</strong>
                </td>
                <td style={{textAlign: "end", paddingTop: "12px"}}>
                  <strong> {Math.round(igstTaxAmount)} </strong>
                </td>
              </tr>
            )}

            <tr style={{borderBottom: "2px solid", borderColor: "gray"}}>
              <td style={{borderRight: "1px solid"}}></td>
              <td style={{borderRight: "1px solid", textAlign: "end"}}>
                <strong>Total</strong>
              </td>
              <td style={{borderRight: "1px solid"}}></td>
              <td style={{borderRight: "1px solid"}}></td>
              <td style={{borderRight: "1px solid"}}></td>
              <td style={{borderRight: "1px solid"}}></td>
              <td style={{textAlign: "end"}}> {Math.round(totalAmount)} </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ServicesChargesInvoice;
