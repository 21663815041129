const isValidEmail = (email) => {
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
  },
  isValidMobile = (mobile) => {
    return /^[6,7,8,9]\d{9}$/.test(mobile);
  },
  defferenceBetweenDates = (date) => {
    date = date.split(" ");
    let expiryDate = new Date(date[0].replace(/-/g, "/"));
    // console.log("new date####", expiryDate);
    var expiryYear = expiryDate.getFullYear();
    var expiryMonth = expiryDate.getMonth() + 1;
    var expiryDay = expiryDate.getDate();

    var expiryDateTime = expiryMonth + "/" + expiryDay + "/" + expiryYear;

    var now = new Date();
    var currentYear = now.getFullYear();
    var currentMonth = now.getMonth() + 1;
    var currentDay = now.getDate();
    var currentDateTime = currentMonth + "/" + currentDay + "/" + currentYear;

    const date1 = new Date(currentDateTime);
    const date2 = new Date(expiryDateTime);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    console.log(diffTime + " milliseconds");
    console.log(diffDays + " days");
    // return new Promise((resolve, reject)=>{
    //   resolve(diffDays)
    // })
    return diffDays;
  };

const dateDifference = (date1, date2, diffIn) => {
  try {
    const date_first = new Date(date1);
    const date_second = new Date(date2);
    let date_difference = "";
    // ✅ Get timestamp in Milliseconds
    const timestamp_first = date_first.getTime();
    const timestamp_second = date_second.getTime();

    // ✅ If you need to convert to Seconds
    const timestampSeconds_1 = Math.floor(timestamp_first / 1000);
    const timestampSeconds_2 = Math.floor(timestamp_second / 1000);
    let diffInSeconds = Math.abs(timestampSeconds_1 - timestampSeconds_2);
    switch (diffIn) {
      case "second":
        date_difference = diffInSeconds;
        break;
      case "hour":
        date_difference = Math.ceil((diffInSeconds % (3600 * 24)) / 3600);
        break;
      default:
        date_difference = Math.floor(diffInSeconds / (3600 * 24));
    }
    return date_difference;
  } catch (error) {
    console.log(error);
  }
};

export { isValidEmail, isValidMobile, defferenceBetweenDates, dateDifference };
