/*
 *  @author       :  Gajendra Singh
 *  @license      :  MIT
 *  @created      :  05/08/2022
 *  @lastModified :  05/08/2022
 *  @modifiedBy   :  Gajendra Singh
 *  @type         :  component
 */
import React, { useEffect, useState } from "react";
import { Button, Spinner, Image, Card } from "react-bootstrap";
import DefaultersService from "../../services/defaulters";
import { isValidEmail, isValidMobile } from "../../services/Misc";
import Modal from "react-modal";
import AgreementDefaulterContact from "./AgreementContact";
import CustomStyle from "./../../styles/customStyle";

const DefaulterContacts = (props) => {
  const { defaulterData, user, setRefresh, refresh, supplier_id, contactType } =
    props;
  const [mobiles, setMobiles] = useState([]);
  const [isEditOpen, setisEditOpen] = useState(false);

  const [emails, setEmails] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [defaulterEmails, setDefaulterEmails] = useState([]);
  const [defaulterMobiles, setDefaulterMobiles] = useState([]);
  const [defaulterAddresses, setDefaulterAddresses] = useState([]);
  const [isEmailError, setIsEmailError] = useState("");
  const [isMobileError, setIsMobileError] = useState("");
  const [isAddressError, setIsAddressError] = useState("");
  const [isEmailSuccess, setIsEmailSuccess] = useState("");
  const [isMobileSuccess, setIsMobileSuccess] = useState("");
  const [isAddressSuccess, setIsAddressSuccess] = useState("");
  const [isFormValid, setIsFormValid] = useState("");
  const [localStorageData, setLocalStorageData] = useState("");
  const [isOpen, setisOpen] = useState(false);
  const [evidence, setEvidence] = useState("");
  const [evidenceName, setEvidenceName] = useState("");
  const [disableAddMore, setDisableAddMore] = useState(false);

  const closeModal = () => {
    setisOpen(false);
    setisEditOpen(false);
  };
  useEffect(() => {
    if (defaulterData?.contacts) {
      let contactEmail = defaulterData.contacts.filter(
        (item) => item.contact_type === "email"
      );
      let contactMobile = defaulterData.contacts.filter(
        (item) => item.contact_type === "mobile"
      );
      let contactAddress = defaulterData.contacts.filter(
        (item) => item.contact_type === "address"
      );

      setDefaulterEmails(contactEmail);
      setDefaulterMobiles(contactMobile);
      setDefaulterAddresses(contactAddress);
      setDisableAddMore(false);
    }

    console.log("Defaulter details -2 ", defaulterData);
  }, [defaulterData]);

  useEffect(() => {
    // Get data from local storage when the component mounts
    let storedData = localStorage.getItem("user");
    storedData = JSON.parse(storedData);

    if (storedData && storedData.status) {
      console.log("storedData.data.role", storedData.data.role);
      setLocalStorageData(storedData.data);
    }
  }, []);

  const duplicateCheck = (contactType) => {
    setIsFormValid(true);
    if (contactType === "email") {
      setIsEmailError("");
      emails.map((newEmail) => {
        let isContactExist = defaulterEmails.findIndex(
          (item) => item.contact_value === newEmail.contact_value
        );
        console.log("validate email id -22 ", newEmail.contact_value);
        if (isContactExist >= 0) {
          console.log("Duplicate entry found - ");
          setIsEmailError("Duplicate email Id!!");
          setIsFormValid(false);
        }
        return newEmail;
      });
    } else if (contactType === "mobile") {
      setIsMobileError("");
      console.log("Duplicate check for mobile number", mobiles);
      mobiles.map((newMobile) => {
        let isContactExist = defaulterMobiles.findIndex(
          (item) => item.contact_value === newMobile.contact_value
        );
        console.log(
          "validate mobile number - ",
          newMobile.contact_value,
          isContactExist
        );
        if (isContactExist >= 0) {
          console.log("Duplicate entry found - ");
          setIsMobileError("Duplicate Mobile Number!!");
          setIsFormValid(false);
        }
        return newMobile;
      });
    } else {
      setIsAddressError("");
      addresses.map((newAddress) => {
        let isContactExist = defaulterAddresses.findIndex(
          (item) => item.contact_value === newAddress.contact_value
        );
        console.log("validate address number - ", newAddress.contact_value);
        if (isContactExist >= 0) {
          console.log("Duplicate entry found - ");
          setIsAddressError("Duplicate address!!");
          setIsFormValid(false);
        }
        return newAddress;
      });
    }
  };

  const validateSubmitData = (contactType) => {
    let isValid = true;
    setIsFormValid(true);
    let isAllBlank = false;
    if (contactType === "email") {
      setIsEmailError("");

      emails.map((newEmail) => {
        console.log("validate email id - ", newEmail.contact_value);
        if (!isValidEmail(newEmail.contact_value)) {
          console.log("Invalid mobile number!!");
          setIsEmailError("Invalid email Id!!");
          isValid = false;
          setIsFormValid(false);
        }
        if (newEmail.contact_value !== "") {
          isAllBlank = true;
        }
        return newEmail;
      });

      if (!isAllBlank) {
        setIsEmailError("Please enter email Id.");
        isValid = false;
      }

      return isValid;
    } else if (contactType === "mobile") {
      isAllBlank = false;
      setIsMobileError("");
      mobiles.map((newMobile) => {
        console.log("validate mobile id - ", newMobile.contact_value);
        if (!isValidMobile(newMobile.contact_value)) {
          console.log("Invalid mobile number!!");
          setIsMobileError("Invalid mobile number!!");
          isValid = false;
          setIsFormValid(false);
        }
        if (newMobile.contact_value !== "") {
          isAllBlank = true;
        }
        return newMobile;
      });
      if (!isAllBlank) {
        setIsMobileError("Please enter mobile number.");
        isValid = false;
      }
      return isValid;
    } else {
      isAllBlank = false;
      setIsMobileError("");
      isValid = false;
      addresses.map((newaddress) => {
        if (newaddress.contact_value) {
          isValid = true;
        }
        if (newaddress.contact_value !== "") {
          isAllBlank = true;
        }
        return newaddress;
      });

      if (!isAllBlank) {
        setIsAddressError("Please enter Address.");
        isValid = false;
      }

      return isValid;
    }
  };

  const addmoreContactRows = (contact_type) => {
    console.log("Action type - ", contact_type);
    setIsEmailSuccess("");
    setIsMobileSuccess("");
    setIsAddressSuccess("");

    switch (contact_type) {
      case "email":
        setEmails((prev) => [
          ...prev,
          { contact_value: "", contact_type: contact_type, id: 0 },
        ]);

        break;
      case "mobile":
        setMobiles((prev) => [
          ...prev,
          { contact_value: "", contact_type: contact_type, id: 0 },
        ]);

        break;
      default:
        setAddresses((prev) => [
          ...prev,
          { contact_value: "", contact_type: contact_type, id: 0 },
        ]);
    }
  };

  const editExistingContact = (contact) => {
    console.log("Action type - ", contact.contact_type);
    switch (contact.contact_type) {
      case "email":
        setEmails((prev) => [
          ...prev,
          {
            contact_value: contact.contact_value,
            contact_type: contact.contact_type,
            id: contact.id,
          },
        ]);

        break;
      case "mobile":
        console.log("mobile row edit - ", contact);
        setMobiles((prev) => [
          ...prev,
          {
            contact_value: contact.contact_value,
            contact_type: contact.contact_type,
            id: contact.id,
          },
        ]);

        break;
      default:
        setAddresses((prev) => [
          ...prev,
          {
            contact_value: contact.contact_value,
            contact_type: contact.contact_type,
            id: contact.id,
          },
        ]);
    }
  };

  const __renderContactFormInput = (contacts, contactType) => {
    if (contactType === "email") {
      return contacts.map((item, index) => {
        return (
          <li key={index} className="inputbox fullwidth mb-5 error">
            <input
              type="email"
              defaultValue={item.contact_value}
              onChange={(e) => {
                const emailInput = e.currentTarget;
                emails[index].contact_value = emailInput.value;
                setDisableAddMore(true);
                if (isValidEmail(emailInput.value)) {
                  emailInput.setCustomValidity("");
                  duplicateCheck("email");
                } else {
                  emailInput.setCustomValidity("Invalid email format");
                }

                // Add a <span> to display the error message
                const errorContainer =
                  emailInput.parentElement.querySelector(".error-message");

                if (errorContainer) {
                  errorContainer.textContent = emailInput.validationMessage;
                }
              }}
            />
            <span className="error-message"></span>
          </li>
        );
      });
    }

    if (contactType === "mobile") {
      return contacts.map((item, index) => {
        return (
          <li key={index} className="inputbox fullwidth mb-5 error">
            <input
              defaultValue={item.contact_value}
              type="number"
              onChange={(e) => {
                const mobileInput = e.currentTarget;
                mobiles[index].contact_value = mobileInput.value;
                console.log("mobile-input", mobileInput.value);
                setDisableAddMore(true);
                if (isValidMobile(mobileInput.value)) {
                  mobileInput.setCustomValidity("");
                  duplicateCheck("mobile");
                } else {
                  mobileInput.setCustomValidity("Invalid Mobile");
                }

                // Add a <span> to display the error message
                const errorContainer =
                  mobileInput.parentElement.querySelector(".error-message");

                if (errorContainer) {
                  errorContainer.textContent = mobileInput.validationMessage;
                }
              }}
            />
            <span className="error-message"></span>
          </li>
        );
      });
    }
    if (contactType === "address") {
      return contacts.map((item, index) => {
        return (
          <li key={index} className="inputbox fullwidth mb-3">
            <input
              defaultValue={item.contact_value}
              type="text"
              onChange={(e) => {
                addresses[index].contact_value = e.currentTarget.value;
                duplicateCheck("address");
                setDisableAddMore(true);
              }}
            />
          </li>
        );
      });
    }
  };

  const __renderContactList = (contacts, contactType) => {
    return contacts.map((item, index) => {
      return (
        <tr key={"ce_" + index} className="inputbox">
          <td>{item.contact_value}</td>{" "}
          <td className="text-right" style={{ textAlign: "right" }}>
            {" "}
            {localStorageData &&
              localStorageData.role &&
              localStorageData.role.id === 2 && (
                <Button
                  className="float-right"
                  variant="secondary"
                  onClick={() => editExistingContact(item)}
                >
                  Change
                </Button>
              )}
          </td>
        </tr>
      );
    });
  };

  const saveContactHandler = async (contacts, contactType) => {
    setIsEmailSuccess("");
    setIsMobileSuccess("");
    setIsAddressSuccess("");
    let isOkay = validateSubmitData(contactType);
    if (!isOkay) return;
    duplicateCheck(contactType);

    if (!isFormValid) return;

    let postData = {
      customer_id: supplier_id
        ? supplier_id
        : defaulterData?.defaulter?.buyer_id,
      defaulter_id: supplier_id ? 0 : defaulterData?.defaulter?.defaulter_id,
      contacts: contacts,
      evidence: evidence ? evidence : "",
      evidenceName: evidenceName ? evidenceName : "",
    };
    console.log("#####ThisConsole.log", postData);

    let result = await DefaultersService.saveDefaulterContacts(postData);
    if (result?.status) {
      setRefresh(!refresh);
      setDisableAddMore(false);
      if (contactType === "email") {
        setIsEmailSuccess("Contacts are saved successfully.");
        setEmails([]);
      } else if (contactType === "mobile") {
        setIsMobileSuccess("Contacts are saved successfully.");
        setMobiles([]);
      } else {
        setIsAddressSuccess("Contacts are saved successfully.");
        setAddresses([]);
      }
    } else {
      if (contactType === "email") {
        setIsEmailError("Enter Email Which Doesn't Exists Already!! ");
      } else if (contactType === "mobile") {
        setIsMobileError("Enter Mobile number Which Doesn't Exists Already!! ");
      }

      console.log("Error", result);
    }
  };

  return (
    <>
      <div className="business-detail-box my-3">
        <Card>
          <Card.Body>
            <Card.Title>
              <div className="card-title h5">Emails</div>
            </Card.Title>
            <table className="table table-striped">
              <tbody>
                {defaulterEmails.length > 0 ? (
                  __renderContactList(defaulterEmails, "email")
                ) : (
                  <tr>
                    <td>No extra email Id available.</td>
                  </tr>
                )}
              </tbody>
            </table>
            {emails.length > 0 && (
              <ul style={{ listStyle: "none" }} className="mx-0 px-0">
                {__renderContactFormInput(emails, "email")}
              </ul>
            )}
            {isEmailError && (
              <p className="alert alert-danger">{isEmailError}</p>
            )}

            {isEmailSuccess && (
              <p className="alert alert-success">{isEmailSuccess}</p>
            )}
            <div className="mt20">
              {emails.length < 1 && !disableAddMore && (
                <Button
                  className="btn btn-small btn-theme"
                  style={{ display: "inline-block", marginRight: "20px" }}
                  onClick={() => addmoreContactRows("email")}
                >
                  Add more
                </Button>
              )}
              {emails.length > 0 && (
                <Button
                  className="btn btn-small btn-theme"
                  style={{ display: "inline-block" }}
                  onClick={() =>
                    // saveContactHandler(emails, "email")
                    setisEditOpen(true)
                  }
                >
                  Save
                </Button>
              )}
            </div>
          </Card.Body>
        </Card>
      </div>
      {contactType == "supplier_contact" && (
        <div className="business-detail-box my-3">
          <Card>
            <Card.Body>
              <Card.Title>
                <div className="card-title h5">Mobile</div>
                <h6>
                  Total mobile numbers you can add :{" "}
                  {Math.max(0, 5 - defaulterMobiles.length)}
                </h6>
              </Card.Title>

              <table className="table table-striped">
                <tbody>
                  {defaulterMobiles.length > 0 ? (
                    __renderContactList(defaulterMobiles, "mobile")
                  ) : (
                    <tr>
                      <td>No extra mobile number available.</td>
                    </tr>
                  )}
                </tbody>
              </table>
              {mobiles.length > 0 && (
                <ul style={{ listStyle: "none" }} className="px-0">
                  {__renderContactFormInput(mobiles, "mobile")}
                </ul>
              )}
              {isMobileError && (
                <p className="alert alert-danger">{isMobileError}</p>
              )}
              {isMobileSuccess && (
                <p className="alert alert-success">{isMobileSuccess}</p>
              )}
              {defaulterMobiles.length < 5 ? (
                <div className="mt20">
                  {mobiles.length < 1 && !disableAddMore && (
                    <Button
                      className="btn btn-small btn-theme"
                      onClick={() => addmoreContactRows("mobile")}
                      style={{ display: "inline-block", marginRight: "20px" }}
                    >
                      Add more
                    </Button>
                  )}
                  {mobiles.length > 0 && (
                    <Button
                      style={{ display: "inline-block" }}
                      className="btn btn-small btn-theme"
                      onClick={() =>
                        // saveContactHandler(mobiles, "mobile")
                        setisEditOpen(true)
                      }
                    >
                      Save
                    </Button>
                  )}
                </div>
              ) : (
                <div>
                  <h6 style={{ color: "#FF0000" }}>
                    For further assistance contact{" "}
                    <a href="mailto:support@creditq.in" target="_blank">
                      support@creditq.in
                    </a>
                  </h6>
                </div>
              )}
            </Card.Body>
          </Card>
        </div>
      )}
      <div className="business-detail-box mb20">
        <Card>
          <Card.Body>
            <Card.Title>
              <div className="card-title h5">Address</div>
            </Card.Title>

            <table className="table table-striped">
              <tbody>
                {defaulterAddresses.length > 0 ? (
                  __renderContactList(defaulterAddresses, "address")
                ) : (
                  <tr>
                    <td>No extra address available.</td>
                  </tr>
                )}
              </tbody>
            </table>
            {addresses.length > 0 && (
              <ul style={{ listStyle: "none" }} className="px-0">
                {__renderContactFormInput(addresses, "address")}
              </ul>
            )}
            {isAddressError && (
              <p className="alert alert-danger">{isAddressError}</p>
            )}
            {isAddressSuccess && (
              <p className="alert alert-success">{isAddressSuccess}</p>
            )}
            <div>
              {addresses.length < 1 && !disableAddMore && (
                <Button
                  className="btn btn-small btn-theme"
                  style={{ display: "inline-block", marginRight: "20px" }}
                  onClick={() => addmoreContactRows("address")}
                >
                  Add more
                </Button>
              )}
              {addresses.length > 0 && (
                <Button
                  className="btn btn-small btn-theme"
                  style={{ display: "inline-block" }}
                  onClick={() =>
                    // saveContactHandler(addresses, "address")
                    setisEditOpen(true)
                  }
                >
                  Save
                </Button>
              )}
            </div>
          </Card.Body>
        </Card>
      </div>
      <Modal
        shouldCloseOnOverlayClick={false}
        isOpen={isEditOpen}
        onRequestClose={closeModal}
        style={CustomStyle}
        setAppElement={"addContact"}
        contentLabel="Example Modal"
      >
        <button
          className="close"
          onClick={closeModal}
          style={{
            fontSize: "20px",
            background: "red",
            borderRadius: "5px",
            color: "black",
            width: "40px",
          }}
        >
          X
        </button>
        <AgreementDefaulterContact
          defaulterData={defaulterData}
          agreeForAgreement={saveContactHandler}
          emails={emails}
          mobiles={mobiles}
          addresses={addresses}
          closeModal={closeModal}
          setProofFileBase64={setEvidence}
          setProofFileName={setEvidenceName}
          contactType={contactType}
        />
      </Modal>
    </>
  );
};

export default DefaulterContacts;
