import {Card} from "react-bootstrap";

const BuyerSnippet = (props) => {
  const {defaulter} = props;
  return (
    <Card className="shadow p-2 border-0">
      <Card.Body>
        <Card.Title>
          <strong>Defaulter</strong>
        </Card.Title>

        <h6>{defaulter?.buyer_trade_name}</h6>
        <div>
          {defaulter?.buyer_gstn ? defaulter?.buyer_gstn : defaulter?.buyer_pan}
        </div>
      </Card.Body>
    </Card>
  );
};
export default BuyerSnippet;
