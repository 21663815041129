import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../../layouts/default";
import { clearMessage } from "../../store/slices/message";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getDefaulter } from "../../store/slices/defaulter";
import * as creditqDefaulterSlice from "../../store/slices/creditqDefaulters/index";
//import { useNavigate } from "react-router-dom";
import {
  updateCallRequest,
  createPaymentActions,
  getCallHoldRequestById,
} from "../../store/slices/payment";
import CallRequestFormEdit from "../../components/CallRequestHold/CallRequestEditForm";

const CallRequestEdit = () => {
  const navigate = useNavigate();
  const { message } = useSelector((state) => state.messageSlice);
  const { defaulter } = useSelector((state) => state.defaulterSlice);
  const { creditqDefaulter } = useSelector(
    (state) => state.creditqDefaultersSlice
  );
  const { holdRequestDetails, holdRequestDetailsLoaded } = useSelector(
    (state) => state.paymentSlice
  );
  console.log("defaultersssssssss", creditqDefaulter);

  const dispatch = useDispatch();
  //const navigate = useNavigate()
  const { defaulter_case_id, holdType, callRequestId, source } = useParams();
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    dispatch(clearMessage());
    // dispatch(createPaymentActions.resetnewPaymentAdded());
    const fetchDefaulter = async (id) => {
      if (id > 0) {
        let filterData = { id: Number(id), commission: true };
        dispatch(getDefaulter({ filterData }));
      }
    };
    fetchDefaulter(defaulter_case_id);
  }, [defaulter_case_id, dispatch]);
  useEffect(() => {
    dispatch(clearMessage());
  }, [defaulter, dispatch]);

  useEffect(() => {
    dispatch(clearMessage());
    // dispatch(createPaymentActions.resetnewPaymentAdded());
    const fetchDefaulter = async (id) => {
      if (id > 0) {
        let filterData = { id: Number(id), commission: true };
        dispatch(creditqDefaulterSlice.getDefaulter({ filterData }));
      }
    };
    fetchDefaulter(defaulter_case_id);
  }, [defaulter_case_id, dispatch, source === "creditqDefaulters"]);
  useEffect(() => {
    dispatch(clearMessage());
  }, [defaulter, dispatch]);

  useEffect(() => {
    dispatch(createPaymentActions.resetnewPaymentAdded());
    const fetchPayment = async (id) => {
      if (id > 0) {
        dispatch(getCallHoldRequestById(id));
      }
    };
    fetchPayment(callRequestId);
  }, [callRequestId, dispatch]);

  const callTypeName =
    holdRequestDetails?.data[0]?.request_type === "complete"
      ? "Complete Hold"
      : "Partial Hold";

  const pageTitle = `${callTypeName} Call request hold for - ${holdType}`;

  const formSubmitHandler = (payload) => {
    try {
      console.log("payload##########", payload);
      setIsDisabled(true);
      dispatch(updateCallRequest(payload)).then(() => {
        setIsDisabled(false);
        if (source === "creditqDefaulters") {
          navigate(`/creditq-defaulters/${defaulter_case_id}`);
          return;
        }
        navigate(`/defaulter/${defaulter_case_id}`);
      });
    } catch (error) {
      console.log(error);
    }
  };
  const callHoldStatus = holdRequestDetails?.data[0]?.request_status
    ? " ( " + holdRequestDetails.data[0].request_status + " ) "
    : "";

  return (
    <Layout heading={pageTitle + " " + callHoldStatus}>
      {message && <div className="alert alert-success">{message}</div>}
      {holdRequestDetails?.data[0]?.id > 0 &&
        holdRequestDetailsLoaded &&
        defaulter && (
          <CallRequestFormEdit
            onformSubmit={formSubmitHandler}
            holdFor={holdType}
            callRequestDetails={holdRequestDetails?.data}
            defaulter={
              source === "creditqDefaulters"
                ? creditqDefaulter?.data?.defaulter
                : defaulter?.data?.defaulter
            }
            contacts={
              source === "creditqDefaulters"
                ? creditqDefaulter?.data?.contacts
                : defaulter?.data?.contacts
            }
            isDiabled={isDisabled}
            source={source}
          />
        )}
    </Layout>
  );
};

export default CallRequestEdit;
