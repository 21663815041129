import React, { useState, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Navbar, Nav, NavDropdown, Row, Col } from "react-bootstrap";
import * as S from "./style";
import logo from "../../assets/images/logo.png";
import { logout } from "../../store/slices/auth";

export const Topnav = (props) => {
	const user = JSON.parse(localStorage.getItem("user"));

	const [isSticky, setIsSticky] = useState(false);
	const stickyRef = props.stickyRef;
	const toggleSticky = useCallback(
		({ top, bottom }) => {
			//console.log("scroll change event", top, bottom);
			if (top <= -3 && bottom > 2 * 68) {
				!isSticky && setIsSticky(true);
			} else {
				isSticky && setIsSticky(false);
			}
		},
		[isSticky]
	);

	useEffect(() => {
		const handleScroll = () => {
			toggleSticky(stickyRef.current.getBoundingClientRect());
		};
		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, [toggleSticky, stickyRef]);

	const dispatch = useDispatch();
	const handleLogout = () => {
		dispatch(logout());
	};
	// const stickyCss = {
	//   position: isSticky ? "fixed" : "relative",
	//   top: 0,
	//   left: 0,
	//   zIndex: 50,
	//   animation:"slide-down 0.7s"
	// };
	return (
		<S.ContainerBox className="fluid p-0" fluid isSticky={isSticky}>
			<Row className="m-0 p-0">
				<Col className="p-0">
					<S.TopNavbar expand="lg" className="shadow-sm">
						<Navbar.Brand href="/app">
							<S.Logo
								src="https://prod-creditq-media.s3.ap-south-1.amazonaws.com/msmecreditq/msme-creditq-logo-1.png"
								alt="logo"
								width="100"
							/>
						</Navbar.Brand>
						<Navbar.Toggle aria-controls="basic-navbar-nav" />
						<Navbar.Collapse id="basic-navbar-nav">
							<Nav className="me-auto">
								{/* <Nav.Link href="#home">Home</Nav.Link>
                <Nav.Link href="#link">Link</Nav.Link> */}
								<NavDropdown
									title={user?.data?.name ? user.data.name : "Action"}
									id="basic-nav-dropdown"
								>
									<NavDropdown.Item onClick={handleLogout}>
										Logout
									</NavDropdown.Item>
									{/* <NavDropdown.Item href="#action/3.3">
                    Something
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="#action/3.4">
                    Separated link
                  </NavDropdown.Item> */}
								</NavDropdown>
							</Nav>
						</Navbar.Collapse>
					</S.TopNavbar>
				</Col>
			</Row>
		</S.ContainerBox>
	);
};
