import { Row, Col, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import * as yup from "yup";
import React, { useState, useMemo, useEffect } from "react";
import { useFormik } from "formik";
import { getPermission } from "../../store/slices/defaulter";
import { checkPermission } from "../../helpers/common";

const SettlementPaymentType = (props) => {
  const { paymentTypeHandler, paymentData = [] } = props;

  const [disable, setDisable] = useState(false);
  const { permission } = useSelector((state) => state.defaulterSlice);

  const dispatch = useDispatch();

  const filteredPaymentData = useMemo(() => {
    let latestSettlementType = [];
    if (Array.isArray(paymentData)) {
      const sortedEntries = [...paymentData]
        .filter((entry) => entry.source !== "customer")
        .sort((a, b) => b.id - a.id)
        .slice(0, 1);
      latestSettlementType = sortedEntries.length > 0 ? sortedEntries[0] : null;
    }

    return latestSettlementType;
  }, [paymentData]);

  useEffect(() => {
    if (filteredPaymentData) {
      const { l1_approval_status, final_approval_status } = filteredPaymentData;
      if (
        l1_approval_status === "PENDING" ||
        (l1_approval_status === "APPROVED" &&
          final_approval_status === "pending")
      ) {
        setDisable(true);
      } else {
        setDisable(false);
      }
    }
  }, [filteredPaymentData]);

  useEffect(() => {
    dispatch(getPermission());
  }, [paymentData]);

  let paidToschema = yup.object().shape({
    payment_type: yup.string().required("Please select Payment Type option."),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      payment_type: "",
    },
    validationSchema: paidToschema,
    onSubmit: (values) => {
      paymentTypeHandler(values.payment_type);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Row className="align-items-center">
        <Col xs={4} className="d-flex align-items-center">
          <input
            className="form-check-input"
            type="radio"
            name="payment_type"
            id="fullPayment"
            onChange={formik.handleChange}
            value="full"
          />
          <label className="form-check-label ps-2 mb-0" htmlFor="fullPayment">
            FULL
          </label>
        </Col>
        <Col xs={4} className="d-flex align-items-center">
          <input
            className="form-check-input"
            type="radio"
            name="payment_type"
            id="partialPayment"
            onChange={formik.handleChange}
            value="partial"
          />
          <label
            className="form-check-label ps-2 mb-0"
            htmlFor="partialPayment"
          >
            PARTIAL
          </label>
        </Col>
        <Col xs={4} className="d-flex align-items-center">
          <input
            className="form-check-input"
            type="radio"
            name="payment_type"
            id="remove"
            onChange={formik.handleChange}
            value="remove"
          />
          <label className="form-check-label ps-2 mb-0" htmlFor="remove">
            REMOVE
          </label>
        </Col>
      </Row>

      <Row>
        <Col sm={12} className="px-2 py-2">
          {formik.touched.payment_type && formik.errors.payment_type ? (
            <span className="error text-danger">
              {formik.errors.payment_type}
            </span>
          ) : null}
        </Col>
        {checkPermission(
          permission?.data,
          "CreditQ Defaulters Settlement",
          "add_edit"
        ) === 1 && (
          <Col className="mt-3">
            <button
              className="btn btn-small btn-theme btn btn-primary"
              type="submit"
              disabled={disable}
            >
              Process
            </button>
            {disable && (
              <div style={{ color: "red" }}>
                {`Settlement request in process`}{" "}
              </div>
            )}
          </Col>
        )}
      </Row>
    </form>
  );
};

export default SettlementPaymentType;
