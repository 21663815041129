import {Row,Col} from "react-bootstrap"
import {format} from "date-fns";
import Classes from "./style.module.css"
import ModalDialog from "../UI/ModalDialog"
import { useState } from "react";

const SettlementPaymentRemarks = (props) =>{
    const {remarks} = props
    const [isShow, setIsshow] = useState(false)
    console.log('remarks########', remarks)
    let latesttwoRemarks = []
    if(remarks){
      latesttwoRemarks = remarks.slice(0,remarks.length===2?remarks.length:2)
    }
    const modalDialogCloseHandler = () =>{
        setIsshow(false)
    }

    const modalDialogOpenHandler = () =>{
        setIsshow(true)
    }

    const remarksExist = remarks && remarks.length>0?true:false

    return (
        <div className="shadow-sm p-3 mb-5 bg-white rounded">
            
            {latesttwoRemarks.map((item)=>{
              return <Row className={Classes.remarkRow} key={item.id}>
                <Col>{item.remarks}
                <p className="px-0 pt-2 fw-bold">{item.name}, {item.role_id===1?'Agent':'Admin'}, {format(
                            new Date(item.date_created),
                            "yyyy-MM-dd"
                          )}</p>
                </Col>
                
               </Row> 
            })}
            <ModalDialog isShow={isShow} title="Remarks" size="xl"
            onClose={modalDialogCloseHandler}>
            {remarks.map((item)=>{
              return <Row className={Classes.remarkRow} key={item.id}>
                <Col>{item.remarks}
                <p className="px-0 pt-2 fw-bold">{item.name}, {item.role_id===1?'Agent':'Admin'}, {format(
                            new Date(item.date_created),
                            "yyyy-MM-dd"
                          )}</p>
                </Col>
                
               </Row> 
            })}
            </ModalDialog>
           {remarksExist &&  <p className={Classes.remarViewAll + " text-right"}><span onClick={modalDialogOpenHandler}>View all remarks</span></p>}
           {!remarksExist && <p>No remarks found!</p>}
        </div>
    )
}

export default SettlementPaymentRemarks;