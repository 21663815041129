import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { displayField } from "../../helpers/DataTable";

export const TableList = (props) => {
  const {
    thead,
    loading,
    dbFieldsForTd,
    fullData,
    tableActions,
    paginationData,
  } = props;

  let tdata = [];

  fullData.map(
    (defaulter, i) =>
      (tdata[i] = dbFieldsForTd.map((field) => {
        if (field.callback) {
          return field.callback(field, defaulter);
        } else {
          return displayField(field, defaulter);
        }
      }))
  );

  const page = paginationData?.page ? paginationData.page : 1;
  const recordsPerPage = paginationData?.recordsPerPage
    ? paginationData.recordsPerPage
    : 10;

  let rowIndex = (page - 1) * recordsPerPage + 1 - 1;

  const getTableActions = (tableActions, tdata, rawData) => {
    console.log("rawData####", rawData);
    if (rawData.defaulters_status !== "wrongly_reported") {
      if (rawData.defaulters_status !== "removed") {
        const makeLink = (tdata, action) => {
          console.log("tdata-actoins", action);
          if (action.link) {
            if (action.linkVars) {
              // action.linkVars.map((linkVar) => {
              //   return action.link.replace(`:${linkVar}`, tdata[linkVar]);
              // });

              let i = 0;
              let length = action.linkVars.length;
              while (i < length) {
                return action.link.replace(
                  `:${action.linkVars[i]}`,
                  rawData[action.linkVars[i]]
                );
              }
            }
          }
        };

        return (
          <>
            {tableActions &&
              tableActions.map((action) => {
                if (action.link) {
                  return (
                    <Link to={makeLink(tdata, action)}>{action.label} </Link>
                  );
                } else {
                  return "";
                }
              })}
          </>
        );
      }
    }
  };

  return (
    <>
      <Table responsive="sm">
        <thead>
          <tr key="heading-row">
            {thead.map((th, i) => (
              <th key={i + "-theading"}>{th}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tdata.length > 0 ? (
            tdata.map((td, i) => (
              <tr key={i + "datarow"}>
                <td>{++rowIndex}</td>
                {td.map((td, j) => (
                  <td key={j + "-td"}>{td}</td>
                ))}
                <td>{getTableActions(tableActions, tdata, fullData[i])}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="7">No record found!!</td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
};
