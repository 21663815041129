import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "../message";
import PaymentsService from "../../../services/creditqDefaulters/payments.service";

export const getPayment = createAsyncThunk(
  "creditq-defaulters/getPayments",
  async (id, thunkAPI) => {
    try {
      const result = await PaymentsService.getPayment(id);
      if (result.status) {
        return result;
      } else {
        thunkAPI.dispatch(setMessage(result.data.message));
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = {
  paymentDetails: null,
};
const paymentTypeSlice = createSlice({
  name: "payment",
  initialState,
  extraReducers: {
    [getPayment.fulfilled]: (state, action) => {
      state.paymentDetails = action.payload;
    },
    [getPayment.rejected]: (state, action) => {
      state.paymentDetails = null;
    },
  },
});

export default paymentTypeSlice.reducer;
