const Sizes = {
  screenWidth: window.innerWidth,
  screenHeight: window.innerHeight,
  fullWidth: "100%",
  fullHeight: "100%",
  containerWidth: 1440,
  formBoxWidth: 400,
  inputHeight: 40,
};
const Colors = {
  Orange: "#ec663b",
  Yellow: "#ec9e28",
  LightGray: "rgba(241, 91, 41, 0.5)",
  AshGray: "#aeaeae",
  Dark: "#191c27",
  White: "#ffffff",
  Black: "#000000",
  BorderColor: "#dddddd",
  Error: "#ea4335",
};

const Styles = {
  container: {
    display: "flex",
    maxHeight: Sizes.screenHeight - 40,
    maxWidth: Sizes.screenWidth - 40,
    width: Sizes.fullWidth,
    height: Sizes.fullHeight,
    margin: "auto",
    padding: 20,
  },
  formBox: {
    flex: 1,
    margin: "auto",
    backgroundColor: Colors.White,
    borderRadius: 8,
    padding: 30,
    maxWidth: Sizes.formBoxWidth,
  },
  content: {
    backgroundColor: "#f0f3f7",
    margin: "auto",
    minWidth: "400px",
    maxWidth: "calc(100vw - 40px)",
    borderRadius: "10px",
    maxHeight: "calc(100vh - 40px)",
    minHeight: "200px",
    position: "relative",
    overflow: "auto",
  },
  overlay: {
    position: "fixed",
    transition: "all 0.3s ease-in-out",
    top: "0",
    height: "100vh",
    left: "0",
    right: "0",
    zIndex: "9999",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
  },
};

export default Styles;
