import { Container, Col } from "react-bootstrap";
import styled from "styled-components";
import { ArrowLeftCircle, CaretRight, CaretLeft } from "react-bootstrap-icons";
import breakpoints from "../../styles/breakpoints";

export const HomeContainer = styled(Container)`
  height: 100vh;
`;

export const LeftCol = styled(Col)`
  -webkit-transition: width 1s ease-in-out;
  -moz-transition: width 1s ease-in-out;
  -o-transition: width 1s ease-in-out;
  transition: width 1s ease-in-out;
  @media only screen and ${breakpoints.sm} {
    width: 100%;
    z-index: 1000;
    flex: none !important;
  }
`;

export const RightCol = styled(Col)``;

export const Topnav = styled(Col)`
  background-color: #112557;
  padding: 0 !important;
`;

export const Heading = styled.h1`
  font-size: 1.4rem;
  margin: 15px 0;
  font-weight: bold;
`;

export const LeftArrowCircle = styled(ArrowLeftCircle)`
  position: absolute;
  top: 20px;
  right: -9px;
  width: 25px;
  height: 25px;
  cursor: pointer;
  color: #cccccc;
  &:hover {
    color: #0d6efd;
  }

  @media only screen and ${breakpoints.sm} {
    display: none;
  }
`;

export const CaretRightSidebar = styled(CaretRight)`
  position: absolute;
  top: 20px;
  right: -9px;
  width: 25px;
  height: 25px;
  cursor: pointer;
  color: #cccccc;
  padding: 2px;
  margin-left: 20px;
  width: 35px;
  height: 35px;
  border: 2px solid #cccccc;
  border-radius: 50%;
  z-index: 10;
  &:hover {
    color: #0d6efd;
    background: #cccccc;
  }

  @media only screen and ${breakpoints.sm} {
    display: none;
  }
`;

export const CaretLeftSidebar = styled(CaretLeft)`
  position: absolute;
  top: 20px;
  right: -9px;
  width: 25px;
  height: 25px;
  cursor: pointer;
  color: #cccccc;
  padding: 2px;
  margin-left: 20px;
  width: 35px;
  height: 35px;
  border: 2px solid #cccccc;
  border-radius: 50%;
  z-index: 10;
  &:hover {
    color: #0d6efd;
    background: #cccccc;
  }

  @media only screen and ${breakpoints.sm} {
    display: none;
  }
`;
