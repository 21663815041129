import {useEffect, useState} from "react";
import Layout from "../../layouts/default";
import {clearMessage} from "../../store/slices/message";
import {useSelector, useDispatch} from "react-redux";
import {useParams} from "react-router-dom";
import {getDefaulter} from "../../store/slices/defaulter";
import {useNavigate} from "react-router-dom";
import SettlementPaymentForm from "../../components/SettlementPayment/PaymentForm";
import {savePayment, createPaymentActions} from "../../store/slices/payment";

const PaymentCreate = () => {
  const {message} = useSelector((state) => state.messageSlice);
  const {defaulter} = useSelector((state) => state.defaulterSlice);
  const {newPaymentAdded} = useSelector((state) => state.paymentSlice);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {defaulter_id, paid_to: paidTo} = useParams();
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    dispatch(clearMessage());
    dispatch(createPaymentActions.resetnewPaymentAdded());
    const fetchDefaulter = async (id) => {
      if (id > 0) {
        let filterData = {id: Number(id), commission: true};
        dispatch(getDefaulter({filterData}));
      }
    };

    fetchDefaulter(defaulter_id);
  }, []);

  useEffect(() => {
    if (newPaymentAdded) {
      navigate(`/defaulter/${defaulter_id}`);
    }
  }, [newPaymentAdded, defaulter_id, navigate]);

  const pageTitle = `Paid to ${paidTo}`;

  const formSubmitHandler = (payload) => {
    try {
      console.log("payload##########", payload);
      setIsDisabled(true);
      dispatch(savePayment(payload)).then(() => {
        setIsDisabled(false);
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Layout heading={pageTitle}>
      {message && <div className="alert alert-success">{message}</div>}
      {defaulter?.data?.defaulter?.id > 0 && (
        <SettlementPaymentForm
          onformSubmit={formSubmitHandler}
          paidTo={paidTo}
          defaulter={defaulter?.data?.defaulter}
          isDisabled={isDisabled}
        />
      )}
    </Layout>
  );
};

export default PaymentCreate;
