import * as API_URL from "../../constants/api";
import * as apiService from "../api";
import { getToken } from "../../helpers/common";

const getPayments = async (filterData) => {
  try {
    let data = {};

    let token = getToken();

    let result = await apiService.post(API_URL.GET_PAYMENTS, filterData, token);
    return result;
    // }
  } catch (e) {
    return { status: false, data: {}, message: e.message };
  }
};

const getPayment = async (id) => {
  try {
    let token = getToken();
    let result = await apiService.get(API_URL.GET_PAYMENT + id, token);
    return result;
    // }
  } catch (e) {
    return { status: false, data: {}, message: e.message };
  }
};

const savePayment = async (payload) => {
  try {
    let token = getToken();
    let result = await apiService.post(API_URL.SAVE_PAYMENT, payload, token);
    return result;
    // }
  } catch (e) {
    return { status: false, data: {}, message: e.message };
  }
};

const saveCallHold = async (payload) => {
  try {
    let token = getToken();
    let result = await apiService.post(API_URL.SAVE_CALL_HOLD, payload, token);
    return result;
    // }
  } catch (e) {
    return { status: false, data: {}, message: e.message };
  }
};

const getCallHoldRequest = async (payload) => {
  try {
    let token = getToken();
    let result = await apiService.post(
      API_URL.GET_CALL_HOLD_REQUEST,
      payload,
      token
    );
    return result;
    // }
  } catch (e) {
    return { status: false, data: {}, message: e.message };
  }
};
const updatePayment = async (formValue) => {
  try {
    let data = {};

    let token = getToken();

    //if (gstn) {console
    data = { formValue };
    //console.log("data", data);
    let result = await apiService.put(API_URL.UPDATE_PAYMENT, data, token);

    return result;
    // }
  } catch (e) {
    return { status: false, data: {}, message: e.message };
  }
};
const updateCallRequestHold = async (formValue) => {
  try {
    let data = {};

    let token = getToken();

    //if (gstn) {console
    data = { formValue };
    console.log("data", data);

    let result = await apiService.put(API_URL.UPDATE_CALL_REQUEST, data, token);

    return result;
    // }
  } catch (e) {
    return { status: false, data: {}, message: e.message };
  }
};

const downloadPaymentProof = async (data, token) => {
  let result = apiService.downloadFile(
    API_URL.PAYMENT_PROOF_DOWNLOAD,
    data,
    token
  );
  return result;
};
const downloadAgreement = async (data, token) => {
  let result = apiService.downloadFile(API_URL.DOWNLOAD_AGREEMENT, data, token);
  return result;
};
const downloadLedger = async (data, token) => {
  let result = apiService.downloadFile(
    API_URL.DOWNLOAD_LATEST_LEDGER,
    data,
    token
  );
  return result;
};

const getSettlementCashback = async (supplierUserId) => {
  try {
    let token = getToken();
    let result = await apiService.get(
      API_URL.GET_SETTLEMENT_CASHBACK + supplierUserId,
      token
    );
    return result;
    // }
  } catch (e) {
    return { status: false, data: {}, message: e.message };
  }
};

const getCallHoldFetchOne = async (id) => {
  try {
    let token = getToken();
    let result = await apiService.get(
      API_URL.GET_CALL_HOLD_REQUEST_BY_ID + id,
      token
    );
    return result;
    // }
  } catch (e) {
    return { status: false, data: {}, message: e.message };
  }
};
const getDefaulterContactForVerification = async (page) => {
  try {
    let token = getToken();
    let result = await apiService.post(
      API_URL.FETCH_DEFAULTER_CONTACTS_FOR_VERIFICATION,
      page,
      token
    );
    return result;
    // }
  } catch (e) {
    return { status: false, data: {}, message: e.message };
  }
};
const updateDefaulterContactVerificationStatus = async(payload) =>{
  try {
    let token = getToken();
    let result = await apiService.post(
      API_URL.UPDATE_DEFAULTER_CONTACTS_VERIFICATION,
      payload,
      token
    );
    return result;
    // }
  } catch (e) {
    return { status: false, data: {}, message: e.message };
  }
}


const defaultersService = {
  getPayments,
  getPayment,
  savePayment,
  updatePayment,
  downloadPaymentProof,
  getSettlementCashback,
  saveCallHold,
  getCallHoldRequest,
  getCallHoldFetchOne,
  updateCallRequestHold,
  downloadAgreement,
  downloadLedger,
  getDefaulterContactForVerification,
  updateDefaulterContactVerificationStatus,
};

export default defaultersService;
