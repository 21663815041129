import { Formik, Field, Form, ErrorMessage } from "formik";
import { useState, useEffect } from "react";
import * as S from "./style";
import * as Yup from "yup";
import { Row, Col, Button, Spinner } from "react-bootstrap";
import { currencyFormatter } from "../../helpers/common";
import { useDispatch, useSelector } from "react-redux";
import {
  settleDefaulter,
  resendDueOffer,
  getSettledHistory,
} from "../../store/slices/defaulter";
import { format } from "date-fns";
import Modal from "react-modal";
import customStyles from "../../constants/style";
import "./modalStyle.css";
import DefaultersService from "../../services/defaulters";

export const SettlementForm = (props) => {
  let { defaulterData, setRefresh, refresh } = props;
  console.log("defaulterData####", defaulterData);
  const initialValues = {
    settled_amount: "",
  };
  const [formData, setFormData] = useState({ initialValues });
  const [submittingForm, setSubmittingForm] = useState(false);
  const [loadingResendEmail, setLoadingResendEmail] = useState(false);
  const [isOpen, setisOpen] = useState(false);
  const [changeDetails, setChangeDetails] = useState(false);
  const [settledHistoryOpen, setsettledHistoryOpen] = useState(false);
  const [settledHistory, setSettledHistory] = useState(false);

  let dispatch = useDispatch();

  useEffect(() => {
    console.log("formData#####", formData);
    setChangeDetails(false);
    setFormData(initialValues);
  }, [defaulterData]);

  var validationSchema = Yup.object().shape({
    settled_amount: Yup.number()
      .required("Amount is required")
      .positive("Amount must be positive")
      .max(
        defaulterData.settlement_amount
          ? defaulterData.payment_due_amount - defaulterData.settlement_amount
          : defaulterData.amount,
        "Amount must be less than or equal to default amount"
      ),
    expected_settlement_date: Yup.date().required(
      "Expected settlement date is required"
    ),
    // settled_comment: Yup.string().required("Comment is required"),
  });
  var validationSchemaForChangeDetails = Yup.object().shape({
    settled_amount: Yup.number()
      .required("Amount is required")
      .positive("Amount must be positive")
      .max(
        defaulterData.settlement_amount
          ? defaulterData.payment_due_amount - defaulterData.settlement_amount
          : defaulterData.amount,
        "Amount must be less than or equal to default amount"
      ),
    expected_settlement_date: Yup.date().required(
      "Expected settlement date is required"
    ),
    settled_comment: Yup.string()
      .required("Comment is required")
      .min(50)
      .max(500),
  });

  const handleSubmit = async (formValue) => {
    console.log("fomrValue", formValue);
    if (window.confirm("Are you sure you want to settle this defaulter?")) {
      setSubmittingForm(true);
      setFormData(formValue);
      formValue.defaulted_amount = defaulterData.amount;
      formValue.rightoff_amount =
        defaulterData.settlement_amount &&
        defaulterData.payment_due_amount - defaulterData.settlement_amount > 0
          ? defaulterData.payment_due_amount -
            defaulterData.settlement_amount -
            formValue.settled_amount
          : defaulterData.amount - formValue.settled_amount;
      formValue.defaulter_case_id = defaulterData.id;
      formValue.changeDetails = changeDetails;

      dispatch(settleDefaulter({ formValue }))
        .then(() => {
          setSubmittingForm(false);
          setRefresh(!refresh);
        })
        .catch(() => {
          setSubmittingForm(false);
        });
    }
  };

  const handleChange = (e) => {
    setFormData({ settled_amount: e.target.value });
  };

  const handleReset = () => {
    setFormData(initialValues);
  };

  const handleResendDueOffer = (defaulterCaseId) => {
    console.log("defaulterCaseId#####", defaulterCaseId);
    if (window.confirm("Are you sure you want to resend due offer?")) {
      setLoadingResendEmail(true);
      dispatch(resendDueOffer({ defaulter_case_id: defaulterCaseId }))
        .then(() => {
          setLoadingResendEmail(false);
        })
        .catch(() => {
          setLoadingResendEmail(false);
        });
    }
  };
  const closeModal = () => {
    setisOpen(false);
  };
  const changeDetailsFunction = () => {
    console.log(
      "defaulterData.defaulter_case_id before",
      defaulterData.defaulter_case_id
    );
    setChangeDetails(true);
    setisOpen(false);
  };
  const settledHistoryFunction = async () => {
    setsettledHistoryOpen(true);
    const result = await DefaultersService.settledHistory({
      defaulter_case_id: defaulterData.id,
    });

    if (result.status) {
      console.log("result#####", result.data);
      setSettledHistory(result);
    }
  };
  const closeSettledHistoryModal = () => {
    setsettledHistoryOpen(false);
  };

  return (
    <>
      {(defaulterData.defaulter_case_id === defaulterData.id &&
        !changeDetails &&
        Number(defaulterData.settled_amount) > 0) ||
      defaulterData.defaulters_status === "full_settled" ||
      defaulterData.defaulters_status === "rejected" ? (
        <>
          {defaulterData.defaulters_status !== "full_settled" &&
            defaulterData.defaulters_status !== "rejected" && (
              <>
                <div className="alert alert-primary" role="alert">
                  Settlement is in process.
                </div>
              </>
            )}
          <table className="table table-striped">
            <tbody>
              <tr>
                <td>Balance Amount:</td>
                <td>
                  {" "}
                  {defaulterData.settlement_amount ||
                  defaulterData.payment_due_amount -
                    defaulterData.settlement_amount >
                    0
                    ? currencyFormatter(
                        defaulterData.payment_due_amount -
                          defaulterData.settlement_amount
                      )
                    : defaulterData.amount}
                </td>
                <td></td>
              </tr>

              <tr>
                <td>Settled Amount</td>
                <td>{currencyFormatter(defaulterData.settled_amount)}</td>
                <td></td>
              </tr>
              <tr>
                <td>Write Off Amount</td>
                <td>{currencyFormatter(defaulterData.rightoff_amount)}</td>
                <td></td>
              </tr>
              <tr>
                <td>Expected Settlement Date</td>
                <td>{defaulterData.expected_settlement_date}</td>
                <td></td>
              </tr>
              <tr>
                <td>Confirmation Status</td>
                <td>
                  {defaulterData.defaulters_status !== "full_settled" &&
                  defaulterData.defaulters_status !== "rejected" ? (
                    <p>Confirmation agreement created</p>
                  ) : (
                    <p>{defaulterData.defaulters_status}</p>
                  )}
                </td>
                <td></td>
              </tr>
              {defaulterData.settled_comment && (
                <tr>
                  <td> Settled comment </td>
                  <td>{defaulterData.settled_comment} </td>
                  <td>
                    <img
                      alt="show setlled history view"
                      onClick={() => {
                        settledHistoryFunction();
                      }}
                      className="settled_history"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAMAAABg3Am1AAAAMFBMVEVHcExTgL5TgL5TgL5TgL5TgL5TgL5TgL5TgL5TgL5TgL5TgL5TgL5TgL5TgL5TgL4ZQ0QxAAAAEHRSTlMAEEBgcFCf3/8gj++AtTDP5C4mDAAAARBJREFUeNrtVEsWwyAIVDEaxcT737Yp2Eo+rWHXRVnkRTODMwQx//jdsM6Bd87eQ08hzi1imEbolHHeBeb0DR7miwgfKaVlX7yzTyN+aaeUS/haWTdY4R7YT13PeECCOzZetmDDjigIR3ygbU9pC76k0GGelmFfeFJbE0mL3W8kKamSMymVdjJbQVbNjpDFZ9qyezzwe2zGm+HIKJAMW3sqU44fimkH9w+LwBvkpI2xrdAIxkL1kfjpre2tZJKMQHuE74pkNUiTZIDhpyBUI6IKAudWE9SS9KbPZcVBWfU/7twayK2Bl62hbz59e3OO0QXK2iuqHQKjMZM25X3MeOUgs8pRqRvG98c9PMf9P342HhNcFnLoo05ZAAAAAElFTkSuQmCC"
                    />
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          <div>
            {defaulterData.defaulters_status !== "full_settled" &&
              defaulterData.defaulters_status !== "rejected" && (
                <>
                  <Button
                    as="input"
                    variant="secondary"
                    type="button"
                    value={
                      loadingResendEmail
                        ? "Processing..."
                        : "Re-send the email to defaulter"
                    }
                    xs={4}
                    md={1}
                    className="my-10"
                    disabled={loadingResendEmail}
                    onClick={() => {
                      setisOpen(true);
                    }}
                  />{" "}
                </>
              )}
            <Modal
              shouldCloseOnOverlayClick={false}
              isOpen={isOpen}
              onRequestClose={closeModal}
              style={customStyles}
              contentLabel="Example Modal"
            >
              <span className="close" onClick={closeModal}>
                &times;
              </span>
              <div style={{ textAlign: "center" }}>
                <p className="text">If all okay, click on next </p>
                <button
                  className="button"
                  type="button"
                  onClick={() => {
                    setisOpen(false);
                    handleResendDueOffer(defaulterData.id);
                  }}
                >
                  Next
                </button>
              </div>
              <div style={{ textAlign: "center", marginTop: "20px" }}>
                <p className="text">
                  Looking for change amount or date, click on change detail{" "}
                </p>
                <button
                  className="button"
                  type="button"
                  onClick={() => {
                    changeDetailsFunction();
                  }}
                >
                  Change details{" "}
                </button>
              </div>
            </Modal>
            {/* <Button
              as="input"
              variant="secondary"
              type="button"
              value="Download due offer letter "
              disabled
              xs={4}
              md={1}
              className="my-10"
            /> */}
          </div>
        </>
      ) : defaulterData.settlement_request_status === "completed" ? (
        <Formik
          initialValues={initialValues}
          validationSchema={
            changeDetails ? validationSchemaForChangeDetails : validationSchema
          }
          validateOnChange={true}
          validateOnBlur={false}
          onSubmit={handleSubmit}
          onReset={handleReset}
        >
          <Form onChange={handleChange}>
            <Row className="mb-3">
              <Col md={6} sm={12}>
                <div className="form-floating">
                  <div>
                    Balance Amount:{" "}
                    <span>
                      {" "}
                      {defaulterData.settlement_amount ||
                      defaulterData.payment_due_amount -
                        defaulterData.settlement_amount >
                        0
                        ? currencyFormatter(
                            defaulterData.payment_due_amount -
                              defaulterData.settlement_amount
                          )
                        : defaulterData.amount}
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md={12} sm={12}>
                <div className="form-floating">
                  <Field
                    name="settled_amount"
                    type="number"
                    className="form-control"
                    placeholder="Enter settled amount"
                    size="sm"
                  />
                  <ErrorMessage
                    name="settled_amount"
                    component="span"
                    className="errorText"
                  />
                  <S.Label htmlFor="defaulter_trade_name">
                    Settled Amount
                  </S.Label>
                </div>
              </Col>
            </Row>
            {formData && formData.settled_amount > 0 && (
              <Row className="mb-3">
                <Col md={6} sm={12}>
                  <div className="form-floating">
                    <div>
                      Right off amount:{" "}
                      <span>
                        {currencyFormatter(
                          defaulterData.payment_due_amount -
                            defaulterData.settlement_amount >
                            0
                            ? defaulterData.payment_due_amount -
                                defaulterData.settlement_amount -
                                formData.settled_amount
                            : defaulterData.amount - formData.settled_amount
                        )}
                      </span>
                    </div>
                  </div>
                </Col>
              </Row>
            )}
            <Row className="mb-3">
              <Col md={12} sm={12}>
                <div className="form-floating">
                  <Field
                    name="expected_settlement_date"
                    type="date"
                    className="form-control"
                    placeholder="Enter expected settlement date"
                    size="sm"
                    min={format(new Date(), "yyyy-MM-dd")}
                  />
                  <ErrorMessage
                    name="expected_settlement_date"
                    component="span"
                    className="errorText"
                  />
                  <S.Label htmlFor="defaulter_trade_name">
                    Expected Settlement Date
                  </S.Label>
                </div>
              </Col>
            </Row>
            {changeDetails && (
              <Row>
                <Col>
                  <div className="form-floating">
                    <Field
                      name="settled_comment"
                      type="text"
                      className="form-control"
                      placeholder="Enter comment"
                      size="sm"
                    />
                    <ErrorMessage
                      name="settled_comment"
                      component="span"
                      className="errorText"
                    />
                    <S.Label htmlFor="defaulter_trade_name">
                      Reason for edit details
                    </S.Label>
                  </div>
                </Col>
              </Row>
            )}
            {!submittingForm ? (
              <Button
                style={{ marginTop: "20px" }}
                as="input"
                type="submit"
                value="Settle Default"
                xs={4}
                md={1}
              />
            ) : (
              <>
                <Spinner animation="border" variant="secondary" />
                <Button
                  as="input"
                  variant="secondary"
                  type="button"
                  value="Please Wait..."
                  disabled
                  xs={4}
                  md={1}
                />
              </>
            )}
          </Form>
        </Formik>
      ) : (
        <>
          <div className="alert alert-danger" role="alert">
            Supplier did not sign the consent agreement yet.
          </div>
        </>
      )}
      <Modal
        shouldCloseOnOverlayClick={false}
        isOpen={settledHistoryOpen}
        onRequestClose={closeSettledHistoryModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <span className="close" onClick={closeSettledHistoryModal}>
          &times;
        </span>
        <div>
          <table className="table">
            <tr>
              <th className="th">Defaulted amount</th>
              <th className="th">Settled amount</th>
              <th className="th">Write Off Amount</th>
              <th className="th">Expected settlement date</th>
              <th className="th">Settled comment</th>
            </tr>
            {settledHistory.status &&
              settledHistory.data.map((item, index) => {
                return (
                  <tr key={index}>
                    <td className="td">{item.defaulted_amount}</td>
                    <td className="td">{item.settled_amount}</td>
                    <td className="td">{item.rightoff_amount}</td>
                    <td className="td">{item.expected_settlement_date}</td>
                    <td className="td">{item.settled_comment}</td>
                  </tr>
                );
              })}
          </table>
        </div>
      </Modal>
    </>
  );
};
