import { Formik, Field, Form, ErrorMessage } from "formik";
//import Button from "../../components/Button";
import * as S from "./style";
import * as Yup from "yup";
import { Row, Col, Button } from "react-bootstrap";

const FilterForm = (props) => {
  const initialValues = {
    defaulter_trade_name: "",
    defaulter_gstn: "",
    supplier_trade_name: "",
    supplier_gstn: "",
    defaulter_pan_no: "",
    defaulter_global_mobile: "",
    defaulter_global_email: "",
    supplier_global_mobile: "",
    supplier_global_email: "",
  };

  // var validationSchema = Yup.object().shape(
  //   {
  //     defaulter_trade_name: Yup.string().when(
  //       ["defaulter_gstn", "supplier_trade_name", "supplier_gstn"],
  //       {
  //         is: (defaulter_gstn, supplier_trade_name, supplier_gstn) =>
  //           !defaulter_gstn && !supplier_trade_name && !supplier_gstn,
  //         then: Yup.string().required("Atleast one of the field is required"),
  //       }
  //     ),
  //   },
  //   {
  //     defaulter_gstn: Yup.string().when(
  //       ["defaulter_trade_name", "supplier_trade_name", "supplier_gstn"],
  //       {
  //         is: (defaulter_trade_name, supplier_trade_name, supplier_gstn) =>
  //           !defaulter_trade_name && !supplier_trade_name && !supplier_gstn,
  //         then: Yup.string().required(),
  //       }
  //     ),
  //   },
  //   {
  //     supplier_trade_name: Yup.string().when(
  //       ["defaulter_trade_name", "defaulter_gstn", "supplier_gstn"],
  //       {
  //         is: (defaulter_trade_name, defaulter_gstn, supplier_gstn) =>
  //           !defaulter_trade_name && !defaulter_gstn && !supplier_gstn,
  //         then: Yup.string().required(),
  //       }
  //     ),
  //   },
  //   {
  //     supplier_trade_name: Yup.string().when(
  //       ["defaulter_trade_name", "defaulter_gstn", "supplier_trade_name"],
  //       {
  //         is: (defaulter_trade_name, defaulter_gstn, supplier_trade_name) =>
  //           !defaulter_trade_name && !defaulter_gstn && !supplier_trade_name,
  //         then: Yup.string().required(),
  //       }
  //     ),
  //   },
  //   [
  //     ["defaulter_gstn", "supplier_trade_name", "supplier_gstn"],
  //     ["defaulter_trade_name", "supplier_trade_name", "supplier_gstn"],
  //     ["defaulter_trade_name", "defaulter_gstn", "supplier_gstn"],
  //     ["defaulter_trade_name", "defaulter_gstn", "supplier_trade_name"],
  //   ]
  // );

  // var validationSchema = Yup.object().shape({
  //   defaulter_gstn: Yup.string().when("defaulter_pan_no", {
  //     is: (defaulter_pan_no) => !defaulter_pan_no,
  //     then: Yup.string().required("Defaulter GSTN or Pan is required "),
  //   }),
  //   supplier_gstn: Yup.string().required("Supplier GSTN is required "),
  // });
  const validationSchema = Yup.object().shape({
    defaulter_gstn: Yup.string().when(
      [
        "defaulter_pan_no",
        "defaulter_global_mobile",
        "defaulter_global_email",
        "supplier_global_mobile",
        "supplier_global_email",
      ],
      {
        is: (
          defaulter_pan_no,
          defaulter_global_mobile,
          defaulter_global_email,
          supplier_global_mobile,
          supplier_global_email
        ) => {
          return (
            !defaulter_pan_no &&
            !defaulter_global_mobile &&
            !defaulter_global_email &&
            !supplier_global_mobile &&
            !supplier_global_email
          );
        },
        then: Yup.string().required("Defaulter GSTN or Pan is required"),
      }
    ),
    supplier_gstn: Yup.string().when(
      [
        "supplier_global_mobile",
        "supplier_global_email",
        "defaulter_global_mobile",
        "defaulter_global_email",
      ],
      {
        is: (
          supplier_global_mobile,
          supplier_global_email,
          defaulter_global_mobile,
          defaulter_global_email
        ) => {
          return (
            !supplier_global_mobile &&
            !supplier_global_email &&
            !defaulter_global_mobile &&
            !defaulter_global_email
          );
        },
        then: Yup.string().required("Supplier GSTN is required"),
      }
    ),
  });

  const handleFilter = (formValue) => {
    console.log("formValue", formValue);
    props.setLoading(true);
    props.setFilterData(formValue);
  };

  const handleReset = () => {
    props.setLoading(false);
    props.setFilterData(props.defaultFilterData);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={handleFilter}
      onReset={handleReset}
    >
      <Form>
        <Row className="mb-3">
          {/* <Col md={3}>
            <div className="form-floating">
              <Field
                name="supplier_trade_name"
                type="text"
                className="form-control"
                placeholder="Enter supplier trade name"
                size="sm"
              />
              <ErrorMessage
                name="supplier_trade_name"
                component="span"
                className="errorText"
              />
              <S.Label htmlFor="supplier_trade_name">
                Supplier trade name
              </S.Label>
            </div>
          </Col>
          */}
          
          <Col md={3}>
            <div className="form-floating ">
              <Field
                name="defaulter_gstn"
                type="text"
                className="form-control"
                placeholder="Enter defaulter gstn"
                size="sm"
                inValid={true}
              />
              <ErrorMessage
                name="defaulter_gstn"
                component="span"
                className="errorText"
              />
              <S.Label htmlFor="defaulter_gstn">Defaulter GSTN</S.Label>
            </div>
          </Col>
          <Col md={3}>
            <div className="form-floating" style={{ marginBottom: "10px" }}>
              <Field
                name="supplier_gstn"
                type="text"
                className="form-control"
                placeholder="Enter supplier gstn"
                size="sm"
              />
              <ErrorMessage
                name="supplier_gstn"
                component="span"
                className="errorText"
              />
              <S.Label htmlFor="supplier_gstn">Supplier GSTN</S.Label>
            </div>
          </Col>
          <Col md={3}>
            <div className="form-floating ">
              <Field
                name="defaulter_pan_no"
                type="text"
                className="form-control"
                placeholder="Enter defaulter pan number"
                size="sm"
                isInvalid="true"
              />
              <ErrorMessage
                name="defaulter_pan_no"
                component="span"
                className="errorText"
              />
              <S.Label htmlFor="defaulter_pan_no">Defaulter Pan Number</S.Label>
            </div>
          </Col>
          <Col md={3}>
            <div className="form-floating ">
              <Field
                name="defaulter_global_mobile"
                type="number"
                className="form-control"
                placeholder="Enter defaulter mobile"
                size="sm"
                inValid={true}
              />
              <ErrorMessage
                name="defaulter_global_mobile"
                component="span"
                className="errorText"
              />
              <S.Label htmlFor="defaulter_global_mobile">
                Defaulter Global Mobile
              </S.Label>
            </div>
          </Col>
          <Col md={3}>
            <div className="form-floating">
              <Field
                name="defaulter_global_email"
                type="text"
                className="form-control"
                placeholder="Enter defaulter email"
                size="sm"
                inValid={true}
              />
              <ErrorMessage
                name="defaulter_global_email"
                component="span"
                className="errorText"
              />
              <S.Label htmlFor="defaulter_global_email">
                Defaulter Global Email
              </S.Label>
            </div>
          </Col>
          {/* <Col md={3}>
            <div className="form-floating ">
              <Field
                name="supplier_global_mobile"
                type="number"
                className="form-control"
                placeholder="Enter supplier mobile"
                size="sm"
                inValid={true}
              />
              <ErrorMessage
                name="supplier_global_mobile"
                component="span"
                className="errorText"
              />
              <S.Label htmlFor="supplier_global_mobile">
                Supplier Global Mobile
              </S.Label>
            </div>
          </Col>
          <Col md={3}>
            <div className="form-floating ">
              <Field
                name="supplier_global_email"
                type="text"
                className="form-control"
                placeholder="Enter supplier email"
                size="sm"
                inValid={true}
              />
              <ErrorMessage
                name="supplier_global_email"
                component="span"
                className="errorText"
              />
              <S.Label htmlFor="defaulter_gstn">Supplier Global Email</S.Label>
            </div>
          </Col> */}
        </Row>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button as="input" type="submit" value="Filter" xs={4} md={1} />{" "}
          <Button
            as="input"
            type="reset"
            variant="light"
            value="Reset"
            xs={4}
            md={1}
            style={{marginLeft:"10px"}}
          />
        </div>
      </Form>
    </Formik>
  );
};

export default FilterForm;
