import React, { useEffect, useState } from "react";
import "./../../styles/inventory.css";
import { Button, Spinner, Image, Card } from "react-bootstrap";

export default function AgreementDefaulterContact(props) {
  // Destructure the necessary props from the parameter
  const {
    agreeForAgreement,
    defaulterData,
    emails,
    mobiles,
    addresses,
    closeModal,
    setProofFileBase64,
    setProofFileName,
    contactType,
  } = props;

  // const [proofFileName, setProofFileName] = useState("");
  const [fileSelected, setFileSelected] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [englishContent, setEnglishContent] = useState("");
  const [hindiContent, setHindiContent] = useState("");
  console.log("defaulterData------", defaulterData);

  useEffect(() => {
    contentSet();
  }, []);

  const contentSet = async () => {
    if (emails.length > 0) {
      setEnglishContent(` Email : ${emails[0].contact_value}`);
      setHindiContent(` ईमेल आईडी :  ${emails[0].contact_value}`);
    }
    if (mobiles.length > 0) {
      console.log("this is mobile ", mobiles);
      setEnglishContent(` Mobile Number : ${mobiles[0].contact_value}`);
      setHindiContent(` मोबाइल नंबर : ${mobiles[0].contact_value}`);
    }
    if (addresses.length > 0) {
      setEnglishContent(` Address :  ${addresses[0].contact_value}`);
      setHindiContent(` पता : ${addresses[0].contact_value}`);
    }
  };

  const handlerContactSave = async () => {
    console.log("emails---mobiles---address", emails, mobiles, addresses);
    if (!fileSelected && contactType === "defaulter_contact") {
      //   showAlert({
      //     type: "error",
      //     msg: "Please upload evidence of add or delete.",
      //   });
      setErrorMsg("Please upload evidence of add or delete.");
    } else {
      if (emails.length > 0) {
        agreeForAgreement(emails, "email");

        closeModal();
      }
      if (mobiles.length > 0) {
        console.log("this is mobile ", mobiles);

        agreeForAgreement(mobiles, "mobile");

        closeModal();
      }
      if (addresses.length > 0) {
        agreeForAgreement(addresses, "address");

        closeModal();
      }
    }
  };
  const _selectFiles = async (e) => {
    var fileToLoad = e.target.files[0];
    console.log("fileToLoad", fileToLoad);
    if (!e.target.files[0]) {
      //   showAlert({ type: "error", msg: "Upload file is required" });
      setErrorMsg("Upload file is required");
    } else {
      setErrorMsg("");
    }

    var fileReader = new FileReader();
    var base64 = [];
    fileReader.onload = function (fileLoadedEvent) {
      base64.push(fileLoadedEvent.target.result);
      setProofFileBase64(base64);
      setProofFileName(fileToLoad.name);
    };

    if (fileToLoad && fileToLoad.size <= 2000000) {
      fileReader.readAsDataURL(fileToLoad);
      setFileSelected(true);
    } else {
      //   showAlert({ type: "error", msg: "File is too large." });
      setErrorMsg("File is too large");
      setFileSelected(false);
    }
  };

  return (
    <>
      <div className="card-agreement">
        <div className="innerCard-agreement">
          <h4 style={{ color: "red", textAlign: "center" }}>
            Information Alert !
          </h4>
          {contactType === "defaulter_contact" ? (
            <>
              <p>
                I,{" "}
                <strong>{defaulterData?.defaulter?.supplier_user_name}</strong>,
                Firm{" "}
                <strong>
                  {" "}
                  {defaulterData?.defaulter?.supplier_trade_name}
                </strong>
                , certify that this <strong>{englishContent} </strong>
                {""} which I am adding, has been verified by me and this{" "}
                <strong>{englishContent} </strong> belongs to{" "}
                <strong>{defaulterData?.defaulter?.buyer_trade_name}</strong>{" "}
                only. If in the future this<strong>{englishContent} </strong>{" "}
                does not belong to this{" "}
                <strong>{defaulterData?.defaulter?.buyer_trade_name}</strong>,
                then the entire responsibility for it will be mine.
              </p>

              <p>
                <span>
                  <strong>Notice</strong>{" "}
                </span>{" "}
                - I agree that once the <strong>{englishContent} </strong> is
                added, this will not be removed.
              </p>
              <br />
              <p>
                मैं,{" "}
                <strong>{defaulterData?.defaulter?.supplier_user_name}</strong>,
                फर्म{" "}
                <strong>{defaulterData?.defaulter?.supplier_trade_name}</strong>{" "}
                , यह प्रमाणित करता हूँ कि यह नया <strong>{hindiContent}</strong>{" "}
                जो मैं ऐड कर रहा हूँ, यह मैंने सत्यापित कर लिया है और यह मोबाइल
                नंबर{" "}
                <strong>{defaulterData?.defaulter?.buyer_trade_name} </strong>का
                ही है | यदि भविष्य में यह <strong>{hindiContent}</strong> इस
                डिफाल्टर पार्टी{" "}
                <strong>{defaulterData?.defaulter?.buyer_trade_name} </strong>का
                नहीं निकलता है तो इसकी समस्त जिम्मेदारी मेरी होगी |
              </p>

              <p>
                <span>
                  <strong>सूचना</strong>{" "}
                </span>
                - मैं इस बात से सहमत हूँ की एक बार{" "}
                <strong>{hindiContent}</strong> ऐड होने के बाद यह हटाया नहीं
                जायेगा |
              </p>
            </>
          ) : (
            <p>
              Are you sure do you want to add this contact details for the
              <br />
              supplier:{" "}
              <strong>
                {defaulterData?.defaulter?.supplier_trade_name}{" "}
              </strong>?{" "}
            </p>
          )}

          {contactType == "defaulter_contact" && (
            <div style={{ marginLeft: "40%", marginBottom: "10px" }}>
              <>
                <h4>Evidence of add or delete</h4>
                <input
                  type="file"
                  name="payment_proof"
                  onChange={(event) => {
                    console.log(
                      "file data###############",
                      event.currentTarget.files[0]
                    );

                    _selectFiles(event);
                  }}
                />
                {errorMsg && <p style={{ color: "red" }}>{errorMsg}</p>}
              </>
            </div>
          )}
          <div className="agreementBox">
            <Button
              type="submit"
              className="btn btn-small btn-theme"
              onClick={handlerContactSave}
            >
              I Agree
            </Button>
            <Button
              style={{ marginLeft: "10px" }}
              type="submit"
              className="btn btn-small btn-theme"
              onClick={closeModal}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
